export default {
  data () {
    return {
      windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    isMobile () {
      return this.windowWidth < 767
    },
    isTablet () {
      return this.windowWidth >= 767 && this.windowWidth < 1173
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  }
}
