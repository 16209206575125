<template>
  <div class="col-lg-12">
    <div class="col-lg-12 pb-2 text-center">
      <h3>{{ $t('Checklist') }}</h3>
    </div>
    <template v-if="task.checklist_id && ((task.checklist && task.checklist['items'].length > 0) ||
                   (task.checklist_result && task.checklist_result.length > 0))">
      <additional-fields-component
          :title="null"
          :disabled="true"
          :items="checklistForm"
          :taskId="task.id"
          :checklistId="task.checklist_id"
      />
    </template>
    <template v-else>
      <div class="py-5 text-center">
        {{$t('NO_STORED_CHECKLISTS')}}
      </div>
    </template>
  </div>
</template>

<script>
import AdditionalFieldsComponent from '@/components/Tasks/CommonComponents/AdditionalFieldsComponent.vue'

export default {
  name: 'ChecklistInfoTaskComponent',
  components: { AdditionalFieldsComponent },
  data () {
    return {
      items: []
    }
  },
  created () {
    this.items = this.task.checklist_result ? this.task.checklist_result : this.task.checklist ? this.task.checklist.items : []
  },
  props: {
    task: Object
  },
  computed: {
    checklistForm () {
      if (this.task.checklist_result) {
        return this.task.checklist_result
      }
      if (this.task.checklist) {
        return this.task.checklist.items
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
