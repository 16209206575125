<template>
  <Form ref="observer" as="div">
    <div class="row pb-3">
      <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
        {{ $t("Basic pay rate editing in the schedule") }}
        <ui-hint :content="$t('BASIC_PAY_RATE_INFO_HINT')"/>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-auto">
        <div class="control-label mr-2 font-weight-bold my-1">{{ $t("Basic pay rate editing in the schedule") }}</div>
        <ui-number-input
          name="rate"
          :label="currency + ' ' + $t('Per hour')"
          :placeholder="$t('Pay rate')"
          class="w-auto"
          :min="0"
          v-model="scheduleRate.rate"
          :validation="{required: false, decimal: 2}"
          :append="true"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <ui-checkbox
          name="change_user_rate"
          v-model="scheduleRate.change_user_rate"
        >
          {{ $t('Set basic pay rate for users on the schedule.') }}
          <ui-hint :content="$t('BASIC_PAY_RATE_HINT')" />
        </ui-checkbox>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-auto mx-3">
        <ui-radio
          name="previous_rate"
          id="not_all_users"
          :label="$t('Set basic pay rate for users that have the value of previous basic pay rate.')"
          :hint="$t('PAY_RATE_OLD_USERS_HINT')"
          v-model="scheduleRate.rate_type"
          :set-value="'previous_rate'"
          :disabled="!scheduleRate.change_user_rate"
        />
      </div>
    </div>
    <div class="row my-1">
      <div class="col-auto mx-3">
        <ui-radio
          name="all_users"
          id="all_users"
          :label="$t('Set basic pay rate for all users')"
          :hint="$t('PAY_RATE_ALL_USERS_HINT')"
          v-model="scheduleRate.rate_type"
          :set-value="'all_users'"
          :disabled="!scheduleRate.change_user_rate"
        />
      </div>
    </div>
    <div class="row my-1">
      <div class="col-lg-12">
        <ui-checkbox
          name="use_date"
          v-model="scheduleRate.use_date"
          :disabled="!scheduleRate.change_user_rate"
        >
          {{ $t('Set basic pay rate for users starting from the selected date.') }}
          <ui-hint :content="$t('Their shifts will be paid for in set fee from the selected date and until the end of the schedule.')" />
        </ui-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-auto mx-3">
        <ui-date-picker
          name="date_from"
          :label="$t('During the period from')"
          :hint="$t('PAYRATE_DURING_FROM_HINT')"
          class="w-auto"
          v-model="scheduleRate.date_from"
          :validation="{required: true}"
          :disabled="!scheduleRate.use_date || !scheduleRate.change_user_rate"
        />
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-lg-12 mx-2">
        <button class="btn btn-success btn-circle" type="button" @click="saveScheduleRate" :disabled="disabled">{{ $t('Save changes') }}</button>
      </div>
    </div>
    <el-divider />
    <div class="row mt-3">
      <div class="col-lg-12">
        <p class="m-0">{{ $t('Set individual basic pay rate value for the user.') }}</p>
        <p class="m-0">{{ $t('It will be active from the selected date and up until the end of the schedule.') }}</p>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          :is-search="true"
        />
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover">
          <thead class="font-weight-bold">
          <tr>
            <td>
              {{$t('Name')}}
            </td>
            <td>
              {{ $t('Pay rate') + ' ' +currency + ' ' + $t('Per hour') }}
            </td>
            <td>
              {{$t('Marks')}}
            </td>
            <td>
              {{$t('Date')}}
            </td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <template v-for="(user, key) in paginationConfig.paginated" :key="key">
            <user-rate-component
              @newMark="value => {
                userMarksChoices.push(value[0])
                user.marks[value[1]] = value[0].id
              }"
              :user="user"
              :userMarksChoices="userMarksChoices"
              :usersData="usersData"
              :project_id="schedule.project_id"
              :index="key"
            >
            </user-rate-component>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination :rows="paginationConfig.rows" @updatePaginated="updatePaginated"></pagination>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import Pagination from '../../CommonComponents/Pagination/Pagination'
import errorMixin from '@/mixins/mixinApiErrors'
import UserRateComponent from '@/components/Schedule/EditScheduleComponents/PayRateComponents/UserRateComponent'

export default {
  name: 'PayRateSchedule',
  mixins: [momentMixin, paginationMixin, errorMixin],
  components: { UserRateComponent, pagination: Pagination },
  data () {
    return {
      scheduleRate: {
        rate: 0,
        change_user_rate: false,
        rate_type: 'all_users',
        use_date: false,
        date_from: moment.utc(new Date()).format(this.backendDateFormat)
      },
      userMarksChoices: [],
      currentSort: 'name',
      currentSortDir: 'asc',
      search: '',
      usersData: [],
      currency: '',
      disabled: false,
      scheduleUsers: []
    }
  },
  created () {
    this.createUserChoices()
    this.$store.dispatch('getMarksByProject', [this.schedule.project_id]).then((response) => {
      if (response) {
        this.userMarksChoices = response
      }
    })
    this.scheduleRate.rate = this.schedule.rate * 1
    this.currency = this.$store.getters.currency

    this.scheduleUsers.forEach(user => {
      // user.rate = this.schedule.usersRate ? this.schedule.usersRate[user.id] : 0
      user.date_from = this.scheduleRate.date_from
      user.rate = Math.round(user.rate * 100) / 100
      this.usersData.push(user)
    })
    this.filteredUsers()
  },
  props: {
    schedule: Object,
    projectUsers: Array,
    preloadUsers: Object
  },
  methods: {
    createUserChoices () {
      this.schedule.users.forEach((user) => {
        if (this.preloadUsers[user.employee_id]) {
          user.name = this.preloadUsers[user.employee_id].full_name
        } else {
          user.name = 'Unknown Name'
        }
        user.rate = user.rate * 1
        this.scheduleUsers.push(user)
      })
    },
    saveScheduleRate () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.disabled = true
          const scheduleRate = {
            rate: parseFloat(this.scheduleRate.rate)
          }

          if (this.scheduleRate.change_user_rate) {
            scheduleRate.rate_type = this.scheduleRate.rate_type
          }

          if (this.scheduleRate.use_date === true) {
            scheduleRate.date_from = this.fromZoneToZone(this.scheduleRate.date_from, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
          }
          this.$store.dispatch('schedulePayRate', [this.$route.params.scheduleId, scheduleRate]).then((schedule) => {
            this.disabled = false
            if (schedule) {
              const usersRates = {}

              schedule.users.forEach(item => {
                usersRates[item.employee_id] = parseFloat(item.rate)
              })

              this.usersData.forEach(user => {
                user.rate = usersRates[user.employee_id]
              })

              this.$forceUpdate()
              this.$notify({
                title: this.$i18n?.t('Success'),
                message: this.$i18n?.t('Schedule successfully edited'),
                position: this.notificationPosition,
                type: 'success'
              })
            }
          })
        }
      })
    },
    filteredUsers () {
      // eslint-disable-next-line
      this.usersData.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') {
          modifier = -1
        }
        if (a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) {
          return -1 * modifier
        }
        if (a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) {
          return modifier
        }

        return 0
      })
      // eslint-disable-next-line
      let rows = this.usersData.filter((user, index) => {
        if (this.search === '') {
          return user
        }
        if (user.name.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1) {
          return true
        }
      })
      this.paginationConfig.rows = rows
    }
  },
  watch: {
    search: function () {
      this.filteredUsers()
    }
  }
}
</script>

<style scoped lang="scss">
  .text-error > input {
    border-color: red;
  }

  .el-select__tags-text {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
</style>
