<template>
  <li v-if="isEmployeeHasPermission('get-employee-admin')"
      id="demo_employees"
      data-cy="company-employees"
      class="nav-item my-auto">
    <router-link :to="`/c/${companyId}/users`" class="nav-link d-none d-md-block my-auto p-0">
      <el-tooltip
        class="item"
        effect="light"
        :content="$t('EMPLOYEES_TOOLTIP')"
        placement="top"
        :show-after="1000">
        <i class="icon-users" style="font-size: 24px"/>
      </el-tooltip>
    </router-link>
    <router-link class="d-md-none dropdown-item p-2 nav-link border-top" :to="`/c/${companyId}/users`">
      <i class="icon-users"></i>
      <span class="menu-color ml-2" v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Employees') }}</span>
    </router-link>
  </li>
  <li v-if="isEmployeeHasPermission('create-employee') &&
           !isEmployeeHasPermission('get-employee-admin')"
      id="demo_employees_projects"
      @click="redirectToEmployees"
      data-cy="company-employees"
      class="nav-item my-auto">
    <div class="nav-link d-none d-md-block my-auto" style="cursor: pointer">
      <i class="icon-users" style="font-size: 24px"></i>
    </div>
    <div class="d-md-none dropdown-item p-2 nav-link border-top" style="cursor: pointer">
      <i class="icon-users"></i>
      <span class="menu-color ml-2" :class="{ mobileItemStyle: isMobile }">{{ $t('Employees') }}</span>
    </div>
  </li>
</template>
<script>
export default {
  name: 'HeaderEmployeesComponent',
  props: {
    isMobile: Boolean
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    redirectToEmployees () {
      const projectId = localStorage.getItem('currentProject')
      this.$router.push(`/c/${this.companyId}/project/${projectId}/users`)
    }
  }
}
</script>
<style scoped lang="scss">
.mobileItemStyle {
  padding-left: 1.25rem;
}
</style>
