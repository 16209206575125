<template>
  <li class="nav-item dropdown d-none d-md-block my-auto">
    <div class="head-link nav-link px-0"
         style="cursor: pointer"
         id="alerts"
         role="button"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false">
              <span class="header-icon text-center d-none d-md-block">
                <el-badge :value="alertsCount" :hidden="alertsCount === 0">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="$t('NOTIFICATIONS_TOOLTIP')"
                    placement="top"
                    :show-after="1000">
                    <i class="icon-bell"/>
                  </el-tooltip>
                </el-badge>
              </span>
    </div>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="alertsDropdown" style="max-width: 450px">
      <alerts/>
    </div>
  </li>
</template>
<script>
import Alerts from '@/components/Alerts.vue'

export default {
  components: { Alerts },
  name: 'HeaderAlertComponent',
  props: {
    alertsCount: Number
  }
}
</script>
<style scoped>
.menu-color {
  color: #4a4a4a !important;
}

.header-icon {
  font-size: 24px;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.badge-pos {
  position: absolute;
  top: 0;
  right: -5px;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>
<style lang="scss">
.el-badge__content.is-dot {
  height: 10px !important;
  width: 10px !important;
}
</style>
