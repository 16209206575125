<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("SERVICE_AREAS_TITLE") }}
          <ui-hint :content="$t('SERVICE_AREAS_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row py-2">
            <div class="col-lg-12">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill mx-3 m-1 col-auto col-auto" type="button" @click="addServiceArea">
                  {{ $t('Create service area') }}
                </button>
              </div>
            </div>
          </div>
          <div>
              <el-table
                :data="list"
                style="width: 100%">
                <el-table-column
                  prop="title"
                  :label="$t('Title')"
                  >
                </el-table-column>
                <el-table-column
                  :label="$t('Color')"
                  >
                  <template #default="scope">
                    <span class="status-color" v-bind:style="{ backgroundColor: scope.row.color }"></span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Number of employees')">
                  <template #default="scope">
                    {{ scope.row.employees.length  }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Actions')"
                  width="180">
                  <template #default="scope">
                    <router-link :to="`/c/${companyId}/tasks-service-area/${scope.row.id}`">
                      <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                    </router-link>
                    <i v-if="!scope.row.is_first" class="icon-trash btn-icon text-danger"
                      @click="deleteServiceArea(scope.row.id)"
                      role="button">
                    </i>
                  </template>
                </el-table-column>
              </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'ServiceAreas',
  components: { DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      searchQuery: '',
      tabs: [],
      activeTab: 'active',
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
    },
    period () {
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange (val) {
      this.$router.replace({ query: { page: val } }).catch(() => {})
      this.init()
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    init () {
      this.$store.dispatch('getTasksServiceAreas', this.companyId).then((response) => {
        if (response) {
          this.list = response
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addServiceArea () {
      this.$router.push(`/c/${this.companyId}/tasks-service-area`)
    },
    deleteServiceArea (serviceAreaId) {
      this.$confirm(this.$i18n.t('You won\'t be able to revert this!'), this.$i18n.t('Are you sure?'), {
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonClass: 'deleteButton',
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTasksServiceArea', [this.companyId, serviceAreaId]).then((response) => {
          this.init()
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
        }).catch((error) => {
          const errorMessage = error.response.data.message
          if (error.response.status === 400 && errorMessage === 'unable to delete system status') {
            this.toastError(this.$i18n.t(errorMessage))
          }
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.status-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
