import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const state = {
  serviceAreasIndexed: {},
  serviceAreas: []
}

const getters = {
  serviceAreasIndexed: state => state.serviceAreasIndexed,
  serviceAreas: state => Object.values(state.serviceAreasIndexed),
  serviceAreaById: (state) => (id) => {
    return state.serviceAreasIndexed[id]
  }
}

const SERVICE_AREAS_SET = 'SERVICE_AREAS_SET'
const SERVICE_AREA_UPDATE = 'SERVICE_AREA_UPDATE'
const SERVICE_AREA_DELETE = 'SERVICE_AREA_DELETE'

const mutations = {
  [SERVICE_AREAS_SET] (state, payload) {
    const areas = {}
    payload.forEach(mark => {
      areas[mark.id] = mark
    })
    state.serviceAreasIndexed = areas
  },
  [SERVICE_AREA_UPDATE] (state, payload) {
    state.serviceAreasIndexed[payload.id] = payload
  },
  [SERVICE_AREA_DELETE] (state, payload) {
    delete state.serviceAreasIndexed[payload]
  }
}

const actions = {
  clearServiceAreaCache () {
    promises = {}
    lastSuccessful = {}
  },
  getTasksServiceAreas ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/tasks/service-areas/`
    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.serviceAreasIndexed)
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/service-areas/`).then(response => {
        commit(SERVICE_AREAS_SET, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    }).finally(() => {
      delete promises[promiseKey]
    })

    promises[promiseKey] = promise

    return promise
  },
  getTasksServiceArea ({ commit }, [companyId, serviceAreaId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/service-areas/${serviceAreaId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateTasksServiceArea ({ commit }, [companyId, serviceAreaId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/tasks/service-areas/${serviceAreaId}`, payload).then(response => {
        commit(SERVICE_AREA_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createTasksServiceArea ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/tasks/service-areas`, payload).then(response => {
        commit(SERVICE_AREA_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteTasksServiceArea ({ commit }, [companyId, serviceAreaId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/tasks/service-areas/${serviceAreaId}`).then(response => {
        commit(SERVICE_AREA_DELETE, serviceAreaId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
