<template>
  <div>
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required" />
    <el-select
      v-model="innerValue"
      :placeholder="placeholder || $t('Select')"
      :clearable="!!clearable"
      :disabled="!!disabled"
      :filterable="filterable !== false"
      class="w-100"
      :allow-create="allowCreate"
      :collapse-tags="true"
      :class="{'is-invalid': isInvalid}"
      size="default"
      :multiple-limit="multipleLimit"
      @change="(val) => $emit('change', val)"
      multiple>
      <el-option
        v-for="(option, key) in options"
        :key="option[keyName || 'id'] || key"
        :value="typeof option === 'object' ? option[valueName || 'id'] : option"
        :label="typeof option === 'object' ? option[labelName || 'name'] : $t(option)"
      />
    </el-select>
    <Field
      ref="ff_field"
      :name="name"
      v-model="innerValue"
      v-slot="{ errorMessage }"
      :rules="validation"
      as="div"
    >
      <el-form-item
        :error="errorMessage"
        :required="validation && !!validation.required"
        class="p-0 mt-0 mb-1"
      />
    </Field>
  </div>
</template>

<script>

export default {
  name: 'ui-multi-select',
  data () {
    return {
      isInvalid: false,
      observer: null
    }
  },
  emits: ['update:modelValue', 'change'],
  props: {
    name: String,
    validation: Object,
    value: Array,
    modelValue: [Array],
    options: Array,
    placeholder: String,
    clearable: Boolean,
    disabled: Boolean,
    filterable: { type: Boolean, default: true },
    keyName: String,
    valueName: String,
    labelName: String,
    label: String,
    hint: String,
    allowCreate: { type: Boolean, default: false },
    multipleLimit: Number
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }
}
</script>
<style lang="scss">
.el-select__wrapper {
  min-height: 40px;
  border-radius: 10px;
}
</style>
