<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'Tasks', params: { companyId: companyId} }">
                  <span class="breadcrumb-item">{{ $t('Tasks')}}</span>
              </router-link>
            </li>
            <li v-if="this.$route.params['taskId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_TASK")}}
              <ui-hint :content="$t('TASK_HINTS')" />
            </li>
            <li v-if="!this.$route.params['taskId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_TASK")}}
              <ui-hint :content="$t('TASK_HINTS')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <form ref="form">
            <div class="row">
                <div class="col-lg-6">
                  <div class="col-lg-12 pb-2">
                    <h3>{{ $t('main information') }}</h3>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Title") }}: <span class="ml-2 font-weight-bold">{{ task.title }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Description") }}: <span class="ml-2 font-weight-bold">{{ task.description }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Client") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.client">{{ task.client['name'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Phone") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.client">{{ task.client['phone'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Email") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.client">{{ task.client['email'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Notes") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.client">{{ task.client['notes'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <template v-if="task.client">
                    <div class="col-lg-12 pb-2">
                      {{ $t("Address") }}:
                      <span class="ml-2 font-weight-bold" v-if="task.client_address">{{ task.client_address['address'] }}</span>
                      <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                    </div>
                  </template>
                  <template v-if="task.client && task.client['fields'].length > 0">
                    <template v-for="(c_field, index) in task.client['fields']"  :key="index">
                      <div class="col-lg-12 pb-2">
                        <template v-if="c_field['field_type'] === 'text'">{{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] }}</span></template>
                        <template v-else-if="c_field['field_type'] === 'number'">{{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ Math.round(c_field['pivot']['value'] * 100) / 100 }}</span></template>
                        <template v-else-if="c_field['field_type'] === 'checkbox'">{{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] ? $t('Yes') : $t('No') }}</span></template>
                        <template v-else-if="c_field['field_type'] === 'select'">{{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ c_field['pivot']['value'] }}</span></template>
                        <template v-else-if="c_field['field_type'] === 'multi_select'">{{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ c_field['pivot']['options'].join(', ') }}</span></template>
                        <template v-else-if="c_field['field_type'] === 'date_range_picker'">
                          {{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ formatDate(c_field['pivot']['options'][0]) }} - {{ formatDate(c_field['pivot']['options'][1]) }}</span>
                        </template>
                        <template v-else-if="c_field['field_type'] === 'date_picker'">
                          {{ c_field['title'] }}: <span class="ml-2 font-weight-bold">{{ formatDate(c_field['pivot']['value']) }}</span>
                        </template>
                        <!-- {{ c_field }} -->
                      </div>
                    </template>
                  </template>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Lead time (in hrs)") }}: <span class="ml-2 font-weight-bold">{{ Math.round(task.lead_time * 100) / 100 }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Scheduled time") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.scheduled_time">{{ task.scheduled_time }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Schedule") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.schedule">{{ task.schedule['name'] }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("Checklist") }}:
                    <span class="ml-2 font-weight-bold" v-if="task.checklist">{{ checklistTitle }}</span>
                    <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("CHECKIN_TIME") }}:
                    <span class="ml-2 font-weight-bold">{{ task.start_time ? toTimeZone(task.start_time).format(localeDateTimeFormat) : $t('NO_DATA') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    {{ $t("CHECKOUT_TIME") }}:
                    <span class="ml-2 font-weight-bold">{{ task.end_time ? toTimeZone(task.end_time).format(localeDateTimeFormat) : $t('NO_DATA') }}</span>
                  </div>
                  <div class="col-lg-12 pb-2">
                    <shift-attach-component
                      @attachShift="(id) => {task.shift_id = id}"
                      :date="task.scheduled_time"
                      v-if="!task.start_time && !task.shift"
                      :taskId="+taskId"
                      :leadTime="+task.lead_time"
                      :tz="tz"
                      :userTask="true"
                      :scheduleId="task.schedule_id">
                    </shift-attach-component>
                  </div>
                  <div class="col-lg-12 pb-2" v-if="task.shift">
                    {{ shiftTimeFrom }} - {{ shiftTimeTo }} {{task.shift.employee.full_name}}
                  </div>
                  <div class="col-lg-12 pb-2">
                    <el-tooltip
                      v-for="mark in task.marks"
                      :key="mark"
                      :content="taskMarksIndexed[mark].name"
                      placement="top" effect="light">
                        <span class="project-color mr-2"
                              :style="{ backgroundColor: taskMarksIndexed[mark].color }"
                        />
                    </el-tooltip>
                  </div>
                  <template v-if="subtask">
                    <div class="title-text">{{parent ? $t('PARENT_TASK_INFO_TITLE') : $t('SUBTASKS_INFO_TITLE')}}</div>
                    <div class="card my-1" v-for="child in subtask" :key="child.id">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <div>
                              {{child.shift.employee.full_name}}
                            </div>
                            <div>
                              {{ $t("Lead time (in hrs)") }}: <span class="ml-2 font-weight-bold">{{ Math.round(child.lead_time * 100) / 100 }}</span>
                            </div>
                            <div>
                              {{ $t("Scheduled time") }}:
                              <span class="ml-2 font-weight-bold" v-if="child.scheduled_time">{{ toTimeZone(child.scheduled_time).format(localeDateTimeFormat) }}</span>
                              <span v-else class="ml-2 font-weight-bold">{{ $t('not indicated') }}</span>
                            </div>
                            <div>
                              <el-tooltip
                                v-for="mark in child.marks"
                                :key="mark"
                                :content="taskMarksIndexed[mark].name"
                                placement="top" effect="light">
                                <span class="project-color mr-2"
                                    :style="{ backgroundColor: taskMarksIndexed[mark].color }"
                              />
                              </el-tooltip>
                            </div>
                          </div>
                          <div class="col-auto my-auto">
                            <i
                              @click="openSubtask(child)"
                              class="icon-eye btn-icon text-primary font-weight-bold" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-lg-6">
                  <ChecklistInfoTaskComponent :task="task" />
                </div>
              <!-- </form> -->
              <div v-if="mapUrl" class="block pl-4">
                <el-image :src="mapUrl" lazy></el-image>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <LogTaskComponent :task="task" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ShiftAttachComponent from '@/components/Tasks/Task/Components/ShiftAttachComponent'
import moment from 'moment'
import mixinDummy from '@/mixins/mixinDummy'
import LogTaskComponent from '@/components/Tasks/CommonComponents/LogTaskComponent'
import ChecklistInfoTaskComponent from '@/components/Tasks/CommonComponents/ChecklistInfoTaskComponent'

export default {
  name: 'Task',
  mixins: [errorMixin, momentMixin, mixinDummy],
  components: { ChecklistInfoTaskComponent, LogTaskComponent, DummyPaymentRequired, ShiftAttachComponent },

  data () {
    return {
      task: {
        title: null,
        description: null,
        client_id: null,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        lead_time: 0.0,
        shift_id: null,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        checklist_name: null,
        logs: []
      },
      schedules: [],
      hoursDiff: 0,
      items: [],
      clients: [],
      mapUrl: '',
      timeout: null,
      taskId: this.$route.params.taskId,
      gApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      parent: null
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    taskMarksIndexed () {
      return this.$store.getters.taskMarksIndexed
    },
    tz () {
      if (!this.task.schedule_id) {
        return 'UTC'
      }
      const scheduleId = this.task.schedule_id
      const schedule = this.$store.getters.scheduleById(scheduleId)
      const project = this.$store.getters.projectById(schedule.project_id)

      return project.time_zone
    },
    storedTask () {
      return this.$store.getters.taskById(this.taskId)
    },
    shiftTimeFrom () {
      let tz = 'UTC'
      if (this.tz) {
        tz = this.tz
      }
      return this.fromZoneToZone(this.task.shift.time_from, 'UTC', tz).format(this.localeDateTimeFormat)
    },
    shiftTimeTo (time) {
      let tz = 'UTC'
      if (this.tz) {
        tz = this.tz
      }
      return this.fromZoneToZone(this.task.shift.time_to, 'UTC', tz).format(this.localeDateTimeFormat)
    },
    checklistTitle () {
      // заголовок чеклиста сохраняется в таске при чекине, если его там нет, берем имя из шаблона чеклсита
      if (this.task.checklist) {
        if (this.task.checklist_name) {
          return this.task.checklist_name
        } else {
          return this.task.checklist.title
        }
      }
      return null
    },
    subtask () {
      if (this.task.children && this.task.children.length > 0) {
        return this.task.children
      }
      if (this.parent) {
        return [this.parent]
      }
      return null
    }
  },

  watch: {
    tz: function (newTz, oldTz) {
      if (this.task.scheduled_time) {
        if (!oldTz) {
          oldTz = 'UTC'
        }
        if (!newTz) {
          newTz = 'UTC'
        }
        this.task.scheduled_time = this.fromZoneToZone(this.task.scheduled_time, oldTz, newTz).format(this.localeDateTimeFormat)
      }
    },
    companyId () {
      this.init()
    },
    items: {
      handler () {
        // this.errors.clear()
      },
      deep: true
    },
    storedTask: {
      handler () {
        this.task = this.storedTask
      },
      deep: true
    }
  },
  methods: {
    openSubtask (child) {
      this.$router.push(`/c/${this.companyId}/tasks/${child.id}/view`)
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.$store.dispatch('getTaskMarks', this.companyId)
      this.$store.dispatch('getTask', [this.companyId, this.taskId]).then((response) => {
        if (response.parent_id) {
          this.$store.dispatch('getTask', [this.companyId, response.parent_id]).then((response) => {
            this.parent = response
          })
        }
        this.items = response.checklist_result ? response.checklist_result : response.checklist ? response.checklist.items : []

        this.getSchedules()
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    getSchedules () {
      this.$store.dispatch('getSchedules', this.companyId)
      this.$store.dispatch('getProjects')
    },
    formatDate (val) {
      return moment(val).format(this.localeDateFormat)
    }
  }
}
</script>

<style>
</style>
