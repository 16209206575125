<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <TaskModal
      v-if="taskModalShow"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      @closeTaskModal="closeTaskModal"
      :title="'TASK_EVENT_MODAL'"
    />
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Tasks") }}
          <ui-hint :content="$t('TASKS_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row mb-1">
            <div class="col-12 col-lg-4">
              <Form ref="observer" as="div" class="form-inline">
                <ui-text-input
                    id="search-task"
                    name="search-task"
                    :placeholder="$t('SEARCH_TASKS_PLACEHOLDER')"
                    v-model='searchQuery'
                    :validation="{required: false, min: searchQuery.length ? 3 : null}"
                    v-on:keyup.enter="searchTasks"
                />
                <el-button
                    class="mx-1 mb-1"
                    type="primary"
                    @click="searchTasks"
                    icon="Search">
                </el-button>
              </Form>
            </div>
            <DateNavigationBar
                :hideDateButton="activeTab === 'backlog'"
                :hideAddTaskButton="false"
                :hideMapButton="false"
                @changeDate="changePeriod"
                @addTask="addTask"
                @openMap="openMap"
            />
          </div>
          <ul class="nav nav-tabs">
            <li v-for="(title, key) in tabsData" :key="key" class="nav-item">
              <a
                :href="'#' +  key"
                class="nav-link"
                :class="{active: activeTab === key}"
                data-toggle="pill"
                role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="handleClick(key)">
                {{ $t(title) }}
              </a>
            </li>
          </ul>
          <splitpanes class="default-theme">
            <pane key="pane_1">
              <tasks-table-component
                :isBank="false"
                :statusesData="statusesData"
                :show-bulk-menu="true"
                @reload="init"
                @list="updateList"
                @selection="updateSelection"
              />
            </pane>
            <pane v-if="drawer" key="pane_2">
              <GoogleMap
                mapId="tasksMApId"
                ref="googleMap"
                :api-key="googleApiKey"
                style="width: 100%; height: 600px"
                :center="center"
                :zoom="12"
                @click="mapClick"
              >
                <Polygon
                  :key="index + '__poly'"
                  v-for="(item, index) in polygons"
                  :options="item"
                />
                <MarkerCluster>
                  <AdvancedMarker
                    :ref="(el) => this.addListener(el, marker)"
                    v-for="marker in markers"
                    :key="marker.id"
                    :options="marker.options"
                  >
                    <InfoWindow v-model="idInfoWindow[marker.id]">
                      <div>
                      <span class="font-weight-bold">
                        {{$t('TASK_TITLE')}}:
                      </span>
                        {{marker.data.title}}
                      </div>
                      <div>
                      <span class="font-weight-bold">
                        {{$t('TASK_CLIENT')}}:
                      </span>
                        {{marker.data.client}}
                      </div>
                      <div>
                      <span class="font-weight-bold">
                        {{$t('TASK_ADDRESS')}}:
                      </span>
                        {{marker.data.address}}
                      </div>
                      <div v-if="marker.data.employee">
                      <span class="font-weight-bold">
                        {{$t('TASK_EMPLOYEE')}}:
                      </span>
                        {{marker.data.employee}}
                      </div>
                    </InfoWindow>
                  </AdvancedMarker>
                </MarkerCluster>
              </GoogleMap>
            </pane>
          </splitpanes>
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
            >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import moment from 'moment'
import TasksTableComponent from '@/components/Tasks/Task/Components/TasksTableComponent'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import mixinDummy from '@/mixins/mixinDummy'
import DateNavigationBar from '@/components/Tasks/CommonComponents/DateNavigationBar.vue'
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { GoogleMap, InfoWindow, AdvancedMarker, MarkerCluster, Polygon } from 'vue3-google-map'
import * as turf from '@turf/turf'

export default {
  name: 'Tasks',
  components: { GoogleMap, AdvancedMarker, InfoWindow, MarkerCluster, Polygon, Splitpanes, Pane, DateNavigationBar, TaskModal, TasksTableComponent, DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy],
  data () {
    return {
      searchQuery: '',
      activeTab: 'active',
      tabsData: {
        active: 'Active',
        completed: 'Completed',
        backlog: 'Backlog'
      },
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      period: [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)],
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      taskModalShow: false,
      taskModalData: null,
      statusesData: [],
      drawer: false,
      tasks: [],
      selection: [],
      googleApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 40.689247, lng: -74.044502 },
      markers: [],
      polygons: [],
      idInfoWindow: {}
    }
  },
  created () {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'active'
    if (this.companyId) {
      this.getStatuses()
      this.checkAccess(this.companyId)
      this.init()
      this.$store.dispatch('getTasksServiceAreas', this.companyId)
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    firstDayOfWeek () {
      return +this.$store.getters.company.salary.week_start + 1
    },
    serviceAreas () {
      return this.$store.getters.serviceAreas
    }
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.getStatuses()
      this.init()
      this.$store.dispatch('getTasksServiceAreas', this.companyId)
    },
    tasks () {
      this.updateMapMarkers()
    },
    selection () {
      this.updateMapMarkers()
    },
    serviceAreas () {
      this.updateMapAreas()
    }
  },
  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'active'
  },
  methods: {
    getStatuses () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then(result => {
        const statusesData = []
        result.forEach(item => {
          statusesData.push({
            text: item.title,
            value: item.title
          })
        })
        this.statusesData = statusesData
      })
    },
    changePeriod (val) {
      this.period = val
      this.init(true)
    },
    searchTasks () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            search: this.searchQuery,
            status: this.activeTab,
            page: this.pagination.currentPage,
            hide_subtasks: true
          }
          if (this.activeTab !== 'backlog') {
            data.date_from = this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat)
            data.date_to = this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
          }
          const payload = '?' + this.stringifyObject(data)
          this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
            this.pagination.currentPage = response.current_page
            this.pagination.total = response.total
          }).catch(() => {})
        }
      })
    },
    handleClick (tab) {
      this.activeTab = tab
      window.location.hash = tab
      this.init()
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange () {
      this.init()
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    init (fromFirstPage = false) {
      const data = {
        status: this.activeTab,
        page: fromFirstPage ? 1 : this.pagination.currentPage,
        hide_subtasks: true
      }
      if (this.activeTab !== 'backlog') {
        data.date_from = this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat)
        data.date_to = this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addTask () {
      this.taskModalData = {
        id: null,
        title: null,
        description: null,
        client_id: null,
        client: null,
        client_address_id: null,
        client_address: null,
        client_name: this.name,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: []
      }
      this.taskModalShow = true
    },
    closeTaskModal () {
      this.taskModalShow = false
      this.taskModalData = null
      // this.init()
      const elements = document.getElementsByClassName('pac-container')
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0])
      }
    },
    openMap () {
      this.drawer = !this.drawer
    },
    updateList (tasks) {
      this.tasks = tasks
    },
    updateSelection (selection) {
      this.selection = selection
    },
    updateMapAreas () {
      const polygons = []
      this.serviceAreas.forEach(area => {
        polygons.push({
          paths: area.geometry.coordinates[0].map(coord => {
            return {
              lat: coord[1],
              lng: coord[0]
            }
          }),
          strokeColor: area.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: area.color,
          fillOpacity: 0.35
        })
      })
      this.polygons = polygons
    },
    updateMapMarkers () {
      let filteredTasks = []
      if (this.selection.length > 0) {
        filteredTasks = this.tasks.filter(task => this.selection.includes(task.id))
      } else {
        filteredTasks = [...this.tasks]
      }
      filteredTasks = filteredTasks.filter(task => task.client_address_id && task.client_address_id > 0 && task.client_address?.longitude)
      if (filteredTasks.length > 0) {
        this.updateCenter(filteredTasks)
      }
      const markers = []
      filteredTasks.forEach(task => {
        if (task.client_address && task.client_address.longitude && task.client_address.latitude) {
          markers.push(this.createMarker(task))
        }
      })
      this.markers = markers
    },
    updateCenter (filteredTasks) {
      const points = []
      filteredTasks.forEach(task => {
        if (task.client_address && task.client_address.longitude) {
          points.push([
            +task.client_address.longitude,
            +task.client_address.latitude
          ])
        }
      })
      const turfPoints = turf.points(points)
      const center = turf.center(turfPoints)
      this.center = {
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0]
      }
    },
    createMarker (task) {
      return {
        id: task.id,
        options: {
          position: { lat: +task.client_address.latitude, lng: +task.client_address.longitude }
          // label: task.title,
          // title: task.title
        },
        pinOptions: {
          background: '#FBBC04'
        },
        data: {
          title: task.title,
          client: task.client.name,
          address: task.client.address,
          employee: task.employee?.full_name
        }
      }
    },
    mapClick (val) {
      // const { lat, lng } = val.latLng
      // console.log(lat(), lng())
    },
    addListener (el, marker) {
      const idInfoWindow = this.idInfoWindow
      if (el && el.marker) {
        el.marker.content.addEventListener('mouseenter', function () {
          idInfoWindow[marker.id] = true
        })
        el.marker.content.addEventListener('mouseleave', function () {
          idInfoWindow[marker.id] = false
        })
      }
    }
  }
}
</script>

<style scoped>
.splitpanes.default-theme .splitpanes__pane {
  background-color: white;
}
.PopupPanel {
  position: fixed;
  top: 30%;
}
</style>
