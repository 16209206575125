<template>
  <client-address-modal
    v-if="showAddAddressModal"
    :clientAddress="addressData"
    :clientId="+clientId"
    @closeClientAddressModal="closeClientAddressModal"
  />
  <div>
    <div class="row">
      <div class="col-lg-12 mt-2 p-3">
        <div class="row mb-2">
          <div class="col d-flex align-items-center">
            <div class="text-primary text-uppercase font-weight-bold">
              {{ $t("CLIENT_ADDRESSES") }}
            </div>
          </div>
          <div class="col-auto pl-1">
            <button
              v-if="clientId"
              class="btn btn-primary btn-sm shadow-sm"
              data-toggle="modal"
              data-target="#ClickClientAddressModal"
              @click="createClientAddress"
              type="submit">
              {{ $t('ADD_NEW_ADDRESS') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <template v-if="addresses.length > 0">
          <el-table
            class="table"
            header-row-class-name="thead-light"
            :data="addresses"
            style="width: 100%">
            <el-table-column
              :label="$t('Address')"
              prop="address">
            </el-table-column>
            <el-table-column
              :label="$t('Service Area')"
              >
              <template #default="scope">
                <div v-if="scope.row.service_area_id">
                  {{ getServiceAreaName(scope.row.service_area_id) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('latitude')"
              prop="latitude">
            </el-table-column>
            <el-table-column
              :label="$t('longitude')"
              prop="longitude">
            </el-table-column>
            <el-table-column
              width="150"
              align="right">
              <template #default="scope">
                <i
                  class="icon-pencil text-primary btn-icon mx-1"
                  data-toggle="modal"
                  data-target="#ClickClientAddressModal"
                  @click="editClientAddress(scope.row)"
                />
                <i class="icon-trash text-danger btn-icon mx-1" @click="handleDelete(scope.row.id)" />
              </template>
            </el-table-column>
          </el-table>
          <GoogleMap
            mapId="tasksMApId"
            ref="googleMap"
            :api-key="googleApiKey"
            style="width: 100%; height: 600px"
            :center="center"
            :zoom="12"
            @click="mapClick"
            :lang="locale"
          >
            <Polygon
              :key="index + '__poly'"
              v-for="(item, index) in polygons"
              :options="item"
            />
            <MarkerCluster>
              <AdvancedMarker
                :ref="(el) => this.addListener(el, marker)"
                v-for="marker in markers"
                :key="marker.id"
                :options="marker.options"
              >
                <InfoWindow v-model="idInfoWindow[marker.id]">
                  <div>
                    {{marker.data.address}}
                  </div>
                </InfoWindow>
              </AdvancedMarker>
            </MarkerCluster>
          </GoogleMap>
        </template>
        <template v-else>
          <div class="card mb-1">
            <div class="card-body text-center p-4">
              {{$t('NO_CLIENT_ADDRESSES')}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientAddressModal from '@/components/Tasks/CommonComponents/ClientAddressModal'
import ToastMixin from '@/mixins/ToastMixin'
import * as turf from '@turf/turf'
import { GoogleMap, InfoWindow, AdvancedMarker, MarkerCluster, Polygon } from 'vue3-google-map'

export default {
  name: 'ClientAddresses',
  components: {
    ClientAddressModal, GoogleMap, InfoWindow, AdvancedMarker, MarkerCluster, Polygon
  },
  mixins: [ToastMixin],
  data () {
    return {
      clientId: this.$route.params.clientId,
      addresses: [],
      addressData: {},
      showAddAddressModal: false,
      // <-- map
      googleApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 40.689247, lng: -74.044502 },
      markers: [],
      polygons: [],
      idInfoWindow: {}
    }
  },
  created () {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.$store.dispatch('getTasksServiceAreas', this.companyId)
  },
  mounted () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      companyId: 'companyId',
      locale: 'locale'
    }),
    serviceAreas () {
      return this.$store.getters.serviceAreas
    },
    isMapReady () {
      return this.$refs.googleMap?.ready
    }
  },
  watch: {
    isMapReady () {
      console.log(123, this.$refs.googleMap)
    },
    companyId () {
      this.init()
    },
    items: {
      handler () {
        // this.errors.clear()
      },
      deep: true
    },
    addresses () {
      this.updateMapMarkers()
    },
    serviceAreas () {
      this.updateMapAreas()
    }
  },
  methods: {
    createClientAddress () {
      this.addressData = {
        id: null,
        client_id: +this.$route.params.clientId,
        address: null,
        address_data: null,
        service_area_id: null,
        longitude: 0.0,
        latitude: 0.0
      }

      this.showAddAddressModal = true
    },
    getServiceAreaName (serviceAreaId) {
      const serviceArea = this.$store.getters.serviceAreaById(serviceAreaId)
      if (serviceArea) {
        return serviceArea.title
      }
      return ''
    },
    editClientAddress (clientAddress) {
      this.addressData = clientAddress
      this.showAddAddressModal = true
    },
    closeClientAddressModal () {
      this.showAddAddressModal = false
      this.addressData = null
      this.init()
    },
    init () {
      if (this.clientId) {
        this.$store.dispatch('getClientAddresses', [this.$store.getters.companyId, this.clientId]).then((response) => {
          if (response) {
            this.addresses = response
          }
        }).catch((error) => {
          console.error('error', error)
        })
      }
    },
    handleDelete (addressId) {
      this.$confirm(this.$t('This will permanently delete the address. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteClientAddress', [this.companyId, this.clientId, addressId]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          this.init()
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    updateMapAreas () {
      const polygons = []
      this.serviceAreas.forEach(area => {
        polygons.push({
          paths: area.geometry.coordinates[0].map(coord => {
            return {
              lat: coord[1],
              lng: coord[0]
            }
          }),
          strokeColor: area.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: area.color,
          fillOpacity: 0.35
        })
      })
      this.polygons = polygons
    },
    updateMapMarkers () {
      if (this.addresses.length > 0) {
        this.updateCenter(this.addresses)
      }
      const markers = []
      this.addresses.forEach(address => {
        markers.push(this.createMarker(address))
      })
      this.markers = markers
    },
    updateCenter (addresses) {
      const points = []
      addresses.forEach(address => {
        if (address.longitude) {
          points.push([
            +address.longitude,
            +address.latitude
          ])
        }
      })
      const turfPoints = turf.points(points)
      const center = turf.center(turfPoints)
      this.center = {
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0]
      }
      // setTimeout(() => {
      //   console.log(12345, this.$refs.googleMap.api)
      //   this.$refs.googleMap.map.fitBounds(turfPoints)
      // }, 1000)
    },
    createMarker (address) {
      return {
        id: address.id,
        options: {
          position: { lat: +address.latitude, lng: +address.longitude }
          // label: task.title,
          // title: task.title
        },
        pinOptions: {
          background: '#FBBC04'
        },
        data: {
          address: address.address
        }
      }
    },
    mapClick (val) {
      // const { lat, lng } = val.latLng
      // console.log(lat(), lng())
    },
    addListener (el, marker) {
      const idInfoWindow = this.idInfoWindow
      if (el && el.marker) {
        el.marker.content.addEventListener('mouseenter', function () {
          idInfoWindow[marker.id] = true
        })
        el.marker.content.addEventListener('mouseleave', function () {
          idInfoWindow[marker.id] = false
        })
      }
    }
  }
}
</script>

<style>
</style>
