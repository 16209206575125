<template>
  <div v-if="loaded">
    <div v-if="dummy" class="dummy-container">
      <dummy-payment-required
          v-if="dummy"
          @activate="activate()"
          :dummyInfo="dummyInfo">
      </dummy-payment-required>
    </div>
    <div v-else>
      <div class="row mb-2 mx-auto">
          <span class="title-text">
            {{ $t("SHIFT_ACTIVITY_MAIN_TITLE") }}
            <ui-hint :content="$t('SHIFT_ACTIVITY_MAIN_TITLE_HINT')" />
          </span>
      </div>
      <ActivityCalendar v-if="loaded" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import ActivityCalendar from '@/components/ShiftActivity/Views/ActivityCalendar.vue'

export default {
  name: 'ShiftActivityMainComponent',
  mixins: [errorMixin, mixinDummy, ToastMixin],
  components: { ActivityCalendar, DummyPaymentRequired },
  data () {
    return {
      loaded: false,
      dummy: true,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  mounted () {
    this.$eventBus.on('reloadDummy', (emiter) => {
      if (emiter === 'activities') {
        this.init()
      }
    })
  },
  beforeUnmount () {
    this.$eventBus.off('reloadDummy')
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    activate () {
      this.dummy = false
      this.init()
    },
    init () {
      this.loaded = false
      this.$store.dispatch('getActivityTemplates', this.companyId).then(_ => {
        this.loaded = true
        this.dummy = false
      }).catch((error) => {
        this.loaded = true
        if (error.response.data.module || error.response.status === 403) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
