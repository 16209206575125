<template>
  <button
    @click="toggleBellMenu"
    class="navbar-toggler ml-auto mt-lg-0 d-md-none mr-2 p-0"
    type="button"
    data-toggle="collapse"
    data-target="#bellModal"
    aria-controls="bellModal"
    aria-expanded="false"
    aria-label="Toggle Notifications">
    <div class="header-icon-mobile text-center">
      <el-badge :value="alertsCount" class="item" :hidden="alertsCount === 0">
        <i class="icon-bell"></i>
      </el-badge>
    </div>
  </button>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarTogglerLoggedBar"
    aria-controls="navbarTogglerLoggedBar"
    aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse vh-100" v-if="isMobile" id="bellModal">
    <alerts/>
  </div>
</template>
<script>
import Alerts from '@/components/Alerts.vue'

export default {
  components: { Alerts },
  name: 'HeaderMobileButtons',
  props: {
    alertsCount: Number,
    isMobile: Boolean
  },
  methods: {
    toggleBellMenu () {
      const el = document.getElementById('navbarTogglerLoggedBar')
      el.classList.remove('show')
    }
  }
}

</script>
<style scoped>

</style>
