<template>
  <div>
    <TaskModal
      v-if="showTaskModal"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      :disableClient="false"
      @closeTaskModal="closeTaskModal"
      @reloadTasks="init"
      :title="'TASK_EVENT_MODAL'"
    />
    <div data-toggle="modal" data-target="#ClickTaskModal" id="taskModalID"></div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-else>
      <div class="row mb-2 mx-auto">
        <span class="title-text">
           {{ $t("Tasks map") }}
          <ui-hint :content="$t('TASKS_MAP_HINTS')" />
        </span>
      </div>
      <div class="row">
        <div v-if="drawer" class="col-12 col-md-3">
          <div class="card">
            <div class="card-body table-responsive">
              <div>
                <div class="row mb-2">
                  <div class="col text-primary">
                    <ui-input-label :label="$t('FILTER_BY_TASKS')" />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <ui-multi-select
                      name="employees"
                      :label="$t('Select employees')"
                      :placeholder="$t('Filter employees')"
                      class="w-100"
                      v-model="selectedEmployees"
                      :options="employees"
                      :key-name="'id'"
                      :label-name="'name'"
                      :value-name="'id'"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <ui-multi-select
                      :label="$t('Select marks')"
                      name="marksMultiplySelect"
                      v-model="marksMultiplySelect"
                      :options="marksMultiplySelectChoices"
                      :validation="{required: false}"
                      class="w-100"
                      :key-name="'key'"
                      :value-name="'id'"
                      :label-name="'name'"
                      :disabled="marksMultiplySelectChoices.length < 1"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <ui-multi-select
                      :label="$t('Select statuses')"
                      name="statusesMultiplySelect"
                      v-model="statusesMultiplySelect"
                      :options="statusesMultiplySelectChoices"
                      :validation="{required: false}"
                      class="w-100"
                      :key-name="'key'"
                      :value-name="'id'"
                      :label-name="'name'"
                      :disabled="statusesMultiplySelectChoices.length < 1"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <button class="btn btn-primary btn-sm rounded-pill shadow-sm" type="button" @click="applyFilters">
                      <i class="icon-search" />
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-secondary btn-sm rounded-pill shadow-sm" type="button" @click="clearTasksFilter">
                      <span class="small">{{$t('Clear')}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body table-responsive">
              <div class="row align-items-center">
                <div class="col">
                  <i class="icon-menu btn-icon mx-1" @click="handleDrawer" :class="drawer ? 'text-primary' : 'text-dark'" />
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-primary btn-sm rounded-pill mx-3"
                    data-toggle="modal"
                    data-target="#ClickTaskModal"
                    type="button"
                    @click="addTask">
                    {{ $t('Create Task') }}
                  </button>
                </div>
                <div class="col-auto">
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(-1)"
                    icon="Arrow-left">
                  </el-button>
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(0)"
                  >
                    {{$t('TODAY')}}
                  </el-button>
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(1)"
                    icon="Arrow-right">
                  </el-button>
                </div>
                <div class="col-auto">
                  <ui-date-picker
                    :pickerType="'daterange'"
                    name="from_to_date"
                    v-model="period"
                    :validation="{required: true}"
                    :clearable="false"
                    :picker-options="{firstDayOfWeek: +$store.getters.company.salary.week_start + 1}"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <GoogleMap
                    mapId="tasksMApId"
                    ref="googleMap"
                    :api-key="googleApiKey"
                    style="width: 100%; height: 600px"
                    :center="center"
                    :zoom="12"
                  >
                    <Polygon
                      :key="index + '__poly'"
                      v-for="(item, index) in polygons"
                      :options="item"
                    />
                    <MarkerCluster>
                      <AdvancedMarker
                        :ref="(el) => this.addListener(el, marker)"
                        v-for="marker in markersToShow"
                        :key="marker.id"
                        :options="marker.options"
                        @click="() => this.clickMarker(marker)"
                      >
                        <InfoWindow v-model="idInfoWindow[marker.id]">
                          <div>
                            <span class="font-weight-bold">
                              {{$t('TASK_TITLE')}}:
                            </span>
                            {{marker.data.title}}
                          </div>
                          <div>
                            <span class="font-weight-bold">
                              {{$t('TASK_CLIENT')}}:
                            </span>
                            {{marker.data.client}}
                          </div>
                          <div>
                            <span class="font-weight-bold">
                              {{$t('TASK_ADDRESS')}}:
                            </span>
                            {{marker.data.address}}
                          </div>
                          <div v-if="marker.data.employee">
                            <span class="font-weight-bold">
                              {{$t('TASK_EMPLOYEE')}}:
                            </span>
                            {{marker.data.employee}}
                          </div>
                        </InfoWindow>
                      </AdvancedMarker>
                    </MarkerCluster>
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import moment from 'moment'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import mixinDummy from '@/mixins/mixinDummy'
import { toRaw } from 'vue'
import { GoogleMap, InfoWindow, AdvancedMarker, MarkerCluster, Polygon } from 'vue3-google-map'
import * as turf from '@turf/turf'

export default {
  name: 'TasksMap',
  components: { TaskModal, DummyPaymentRequired, GoogleMap, AdvancedMarker, InfoWindow, MarkerCluster, Polygon },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy],
  data () {
    return {
      center: { lat: 40.689247, lng: -74.044502 },
      period: [moment().format(this.backendDateFormat), moment().add(1, 'days').format(this.backendDateFormat)],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      taskModalData: {
        id: null,
        children: []
      },
      showTaskModal: false,
      markers: [],
      searchMarkers: [],
      markersToShow: [],
      selectedEmployees: [],
      marksMultiplySelect: [],
      statusesMultiplySelect: [],
      marksMultiplySelectChoices: [],
      statusesMultiplySelectChoices: [],
      employees: [],
      drawer: true,
      googleApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      mapMarkers: [],
      polygons: [],
      idInfoWindow: {}
    }
  },
  created () {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.getTasksMarks()
      this.getStatuses()
      this.$store.dispatch('getTasksServiceAreas', this.companyId)
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    serviceAreas () {
      return this.$store.getters.serviceAreas
    }
  },
  watch: {
    searchMarkers () {
      this.updateMarkers(this.searchMarkers)
    },
    companyId () {
      if (this.companyId) {
        this.checkAccess(this.companyId)
        this.getTasksMarks()
        this.getStatuses()
        this.$store.dispatch('getTasksServiceAreas', this.companyId)
        this.init()
      }
    },
    period () {
      this.init()
    },
    serviceAreas () {
      this.updateMapAreas()
    }
  },
  methods: {
    addTask () {
      this.taskModalData = {
        id: null,
        title: null,
        description: null,
        client_id: null,
        client: null,
        client_name: this.name,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: []
      }
      this.showTaskModal = true
    },
    applyFilters () {
      let filtered = []
      if (this.selectedEmployees.length > 0) {
        filtered = this.markers.filter(item => this.selectedEmployees.includes(item.employee_id))
      } else {
        filtered = this.markers
      }
      if (this.marksMultiplySelect.length > 0) {
        filtered = filtered.filter(item => {
          const filteredArray = item.marks.filter(mark => this.marksMultiplySelect.includes(mark))
          return filteredArray.length > 0
        })
      }
      if (this.statusesMultiplySelect.length > 0) {
        filtered = filtered.filter(item => this.statusesMultiplySelect.includes(item.status.id))
      }
      this.searchMarkers = filtered
      // searchMarkers
    },
    changeDate (val) {
      if (val === 0) {
        this.period = [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
      } else {
        const date = moment(this.period[0], this.backendDateFormat).add(val, 'days').format(this.backendDateFormat)
        this.period = [date, date]
      }
    },
    updateMarkers (tasks) {
      this.markersToShow = []
      const marks = []
      tasks.forEach(task => {
        if (task.client_address && task.client_address.longitude && task.client_address.latitude) {
          marks.push(this.createMarker(task))
        }
      })
      if (marks.length > 0) {
        this.updateCenter(tasks)
      }
      this.markersToShow = marks
    },
    clickMarker (marker) {
      this.taskModalData = this.markers.filter(task => task.id === marker.id)[0]
      this.showTaskModal = true
      document.getElementById('taskModalID').click()
      this.$nextTick(() => {
        document.getElementById('taskModalID').click()
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    closeTaskModal () {
      this.showTaskModal = false
      this.taskModalData = {
        id: null,
        children: []
      }
      this.init()
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    stringifyObject (objData, joinSymbol = '&') {
      let options = []
      for (let optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    init () {
      this.markers = []
      this.searchMarkers = []
      this.selectedEmployees = []
      this.marksMultiplySelect = []
      this.statusesMultiplySelect = []
      if (!this.hasCompany) {
        this.accessErrorRedirect('In order to access this page, you must first create a company')
      }
      let data = {
        status: 'all',
        // page: this.pagination.currentPage,
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
        if (response) {
          let tasks = response.filter(item => item.client !== null)
          // console.log(tasks)
          let employees = {}
          tasks.map((task) => {
            task.icon = null

            // дефолтный цвет таски
            let statusColor = '#958d19'

            // если нет статусов по дефолту New
            task.status = {
              title: this.$i18n?.t('New'),
              time: null
            }

            // инфа по последнему выставленному статусу
            if (task.logs && task.logs.length > 0) {
              const log = task.logs[task.logs.length - 1]
              statusColor = log.status.color
              task.status = log.status
              task.status.time = log.created_at
            }

            // урл принимает цвет без символа #
            statusColor = statusColor.replace('#', '')
            task.icon = `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${statusColor}` // 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'

            // координаты пометки на карте
            if (task.client) {
              task.client.position = {
                lat: task.client.latitude,
                lng: task.client.longitude
              }
              task.client.address_formated = task.client.address_data && task.client.address_data.name ? task.client.address_data.name : task.client.address
            }

            if (task.employee_id && task.employee && !(task.employee_id in employees)) {
              employees[task.employee_id] = {
                name: task.employee.full_name,
                id: task.employee_id
              }
            }
            return task
          })
          this.employees = Object.values(employees)
          this.markers = toRaw(tasks)
          this.searchMarkers = toRaw(tasks)
        }
        // console.log(response.filter(item => item.client !== null))
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        // console.log(error)
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    getTasksMarks () {
      this.$store.dispatch('getTaskMarks', this.companyId).then(marks => {
        this.marksMultiplySelectChoices = []
        marks.forEach(mark => {
          this.marksMultiplySelectChoices.push({
            id: mark.id,
            name: mark.name
          })
        })
      })
    },
    getStatuses () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then(statuses => {
        this.statusesMultiplySelectChoices = []
        statuses.forEach(status => {
          if (!status.is_first) {
            this.statusesMultiplySelectChoices.push({
              id: status.id,
              name: status.title
            })
          }
        })
      })
    },
    clearTasksFilter () {
      this.selectedEmployees = []
      this.marksMultiplySelect = []
      this.statusesMultiplySelect = []
      this.applyFilters()
    },
    handleDrawer () {
      this.drawer = !this.drawer
    },
    updateCenter (filteredTasks) {
      const points = []
      filteredTasks.forEach(task => {
        if (task.client_address && task.client_address.longitude) {
          points.push([
            +task.client_address.longitude,
            +task.client_address.latitude
          ])
        }
      })
      const turfPoints = turf.points(points)
      const center = turf.center(turfPoints)
      this.center = {
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0]
      }
    },
    createMarker (task) {
      return {
        id: task.id,
        options: {
          position: { lat: +task.client_address.latitude, lng: +task.client_address.longitude }
          // label: task.title,
          // title: task.title
        },
        pinOptions: {
          background: '#FBBC04'
        },
        data: {
          title: task.title,
          client: task.client.name,
          address: task.client.address,
          employee: task.employee?.full_name
        }
      }
    },
    addListener (el, marker) {
      const idInfoWindow = this.idInfoWindow
      if (el && el.marker) {
        el.marker.content.addEventListener('mouseenter', function () {
          idInfoWindow[marker.id] = true
        })
        el.marker.content.addEventListener('mouseleave', function () {
          idInfoWindow[marker.id] = false
        })
      }
    },
    updateMapAreas () {
      const polygons = []
      this.serviceAreas.forEach(area => {
        polygons.push({
          paths: area.geometry.coordinates[0].map(coord => {
            return {
              lat: coord[1],
              lng: coord[0]
            }
          }),
          strokeColor: area.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: area.color,
          fillOpacity: 0.35
        })
      })
      this.polygons = polygons
    }
  }
}
</script>

<style scoped>
</style>
