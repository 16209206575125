<template>
  <div>
    <Form ref="observer">
      <div class="row mt-2">
        <div class="col-12">
          <label class="control-label font-weight-bold">
            {{ $t("Address") }}
          </label>
          <google-map-autocomplete
            @place_changed="setPlace"
            :address="clientAddress.address"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ui-select
            :label=" $t('Service area')"
            :hint="$t('ADDRESS_SERVICE_AREA_HINT')"
            name="address_service_area_hint"
            v-model="clientAddress.service_area_id"
            :clearable="true"
            :filterable="true"
            class="w-100"
            :options="serviceAreasList"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
            :validation="{required: false}"
            @change="changeAddressArea"
          />
        </div>
        <div class="col-6">
        </div>
      </div>
    </Form>
    <div class="block pt-2">
      <GoogleMap
        mapId="tasksMApId"
        ref="googleMap"
        :api-key="googleApiKey"
        style="width: 100%; height: 600px"
        :center="position"
        :zoom="12"
        @click="handleMapClick"
        :lang="lang"
      >
        <Polygon
          :key="index + '__poly'"
          v-for="(item, index) in polygons"
          :options="item"
          @click="handleMapClick"
        />
        <MarkerCluster>
          <AdvancedMarker
            v-for="marker in markers"
            :key="marker.id"
            :options="marker.options"
          >
          </AdvancedMarker>
        </MarkerCluster>
      </GoogleMap>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import * as turf from '@turf/turf'
import { AdvancedMarker, GoogleMap, MarkerCluster, Polygon } from 'vue3-google-map'
import GoogleMapAutocomplete from '@/ui/google-map-autocomplete.vue'
import { Loader } from '@googlemaps/js-api-loader'

export default {
  name: 'ClientAddressForm',
  components: { GoogleMapAutocomplete, MarkerCluster, AdvancedMarker, Polygon, GoogleMap },
  mixins: [errorMixin],
  data () {
    return {
      clientAddress: this.clientAddressData,
      serviceAreasList: [],
      showMarker: true,
      point: turf.point([0, 0]),
      googleApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      markers: [],
      polygons: [],
      idInfoWindow: {},
      position: {
        lat: 0,
        lng: 0
      },
      geocoding: null
    }
  },
  props: {
    clientAddressData: {
      type: Object,
      required: true
    }
  },
  created () {
    if (this.clientAddressData.address) {
      this.position = {
        lat: +this.clientAddressData.latitude,
        lng: +this.clientAddressData.longitude
      }
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    }
    this.$store.dispatch('getTasksServiceAreas', this.companyId).then((response) => {
      this.updateMapMarkers()
      this.setPosition({
        lat: +this.clientAddress.latitude,
        lng: +this.clientAddress.longitude
      })
    }).catch(() => {})

    const elements = document.getElementsByClassName('pac-container')
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  },
  async mounted () {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
      language: this.lang
    })
    const Geocoding = await loader.importLibrary('geocoding')
    this.geocoding = new Geocoding.Geocoder()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      serviceAreas: 'serviceAreas'
    })
  },
  watch: {
    position (newPosition) {
      if (this.clientAddressData.address || this.clientAddress.address) {
        this.setPosition(newPosition)
      }
    },
    clientAddress () {
      this.updateMapMarkers()
      if (this.clientAddress.latitude && this.clientAddress.longitude) {
        this.position = {
          lat: +this.clientAddress.latitude,
          lng: +this.clientAddress.longitude
        }
      }
    },
    serviceAreasList () {
      this.updateMapAreas()
    }
  },
  methods: {
    handleMapClick (coord) {
      this.geocoding.geocode({
        location: { lat: coord.latLng.lat(), lng: coord.latLng.lng() }
      }).then(response => {
        if (response.results && response.results.length > 0) {
          const place = response.results[0]
          this.setPlace(place)
        }
      })
    },
    setPosition (newPosition) {
      this.point = turf.point([newPosition.lng, newPosition.lat])

      let filteredAreas = []
      this.serviceAreas.forEach(element => {
        if (element.geometry && element.geometry.coordinates && element.geometry.coordinates.length > 0) {
          const isPointInPolygon = turf.booleanPointInPolygon(this.point, element.geometry)
          if (isPointInPolygon) {
            filteredAreas.push(element)
          }
        }
      })
      this.serviceAreasList = filteredAreas

      if (!filteredAreas.length) {
        this.service_area_id = null
      }
    },
    setPlace (place) {
      let clientAddress = {}

      clientAddress.longitude = place.geometry.location.lng()
      clientAddress.latitude = place.geometry.location.lat()

      clientAddress.address = place.formatted_address
      clientAddress.address_data = {
        name: place.name,
        address_components: place.address_components,
        formatted_address: place.formatted_address,
        vicinity: place.vicinity
      }

      this.clientAddress = clientAddress
      this.position = {
        lat: +this.clientAddress.latitude,
        lng: +this.clientAddress.longitude
      }
      this.changeAddressArea()
    },
    changeAddressArea () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$emit('addressChange', this.clientAddress)
        }
      })
    },
    // <------------
    updateMapAreas () {
      const polygons = []
      this.serviceAreasList.forEach(area => {
        polygons.push({
          paths: area.geometry.coordinates[0].map(coord => {
            return {
              lat: coord[1],
              lng: coord[0]
            }
          }),
          strokeColor: area.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: area.color,
          fillOpacity: 0.35
        })
      })
      this.polygons = polygons
    },
    updateMapMarkers () {
      const markers = []
      if (this.clientAddress.latitude && this.clientAddress.longitude) {
        markers.push(this.createMarker(this.clientAddress))
      }
      this.markers = markers
    },
    createMarker (address) {
      return {
        id: 'marker_id',
        options: {
          position: { lat: +address.latitude, lng: +address.longitude }
          // label: task.title,
          // title: task.title
        },
        pinOptions: {
          background: '#FBBC04'
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
