<template>
  <li class="nav-item  dropdown border-top no-md-border my-auto">
    <div class="btn-group nav-link px-0" v-bind:class="{ 'py-0' : isMobile }">
      <router-link
        :is="$store.getters.isDataLoading ? 'span' : 'router-link'"
        :to="currentProject ? `/c/${companyId}/p/${currentProject}/schedule` : `/c/${companyId}/schedule/company`"
        id="demo_company_schedule">
                  <span class="d-md-none p-0 mx-2">
                      <i class="icon-calendar mobile-menu-icon"></i>
                  </span>
        <button type="button" class="btn btn-light menu-color">
          {{ $t('SCHEDULING') }}
        </button>
      </router-link>
      <button v-if="isEmployeeHasPermission('get projects by company') ||
                              isEmployeeHasPermission('get related projects full data') ||
                               isEmployeeHasPermission('get-company-schedules-admin') ||
                              isEmployeeHasPermission('get-company-schedules-manager') ||
                               isEmployeeHasPermission('create-template')"
              type="button"
              class="btn dropdown-toggle dropdown-toggle-split scheduling-btn" data-toggle="dropdown"
              aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>
        <el-badge :value="companyReminder" class="item d-flex badge-pos" :hidden="companyReminder === 0">
        </el-badge>
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <router-link id="demo_projects"
                     v-if="isEmployeeHasPermission('get projects by company') ||
                              isEmployeeHasPermission('get related projects full data')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/projects`">
          <el-badge is-dot class="item" :hidden="!!hasProject">
            <i class="icon-package"></i>
          </el-badge>
          <span class="menu-color ml-2">{{ $t('Projects') }}</span>
        </router-link>
        <router-link id="demo_schedules"
                     v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
                              isEmployeeHasPermission('get-company-schedules-manager')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/schedules`">
          <el-badge is-dot class="item" :hidden="!!hasSchedule">
            <i class="icon-calendar"></i>
          </el-badge>
          <span class="menu-color ml-2">{{ $t('Schedules list') }}</span>
        </router-link>
        <router-link v-if="isEmployeeHasPermission('create-template')"
                     class="dropdown-item"
                     :to="`/c/${companyId}/templates`">
          <i class="icon-eye"></i>
          <span class="menu-color ml-2">{{ $t('View templates') }}</span>
        </router-link>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: 'HeaderShedulingComponent',
  props: {
    isMobile: Boolean,
    currentProject: [Number, String],
    hasProjectsByCompany: Boolean
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    companyReminder () {
      let counter = 0
      if (this.isEmployeeHasPermission('create-schedule')) {
        if (!this.isWizardPassed) {
          counter++
        }
        if (!this.hasSchedule) {
          counter++
        }
        if (!this.hasProject) {
          counter++
        }
      }

      return counter
    },
    isWizardPassed () {
      return true
    },
    hasSchedule () {
      return this.$store.getters.hasSchedule
    },
    hasProject () {
      return this.hasProjectsByCompany
    }
  }
}
</script>
<style scoped lang="scss">

.menu-color {
  color: #4a4a4a !important;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle {
  border-radius: 0px 7px 7px 0px !important;
}

a#demo_company_schedule .btn {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
  border: none;
}

a#demo_company_schedule .btn:hover,
.scheduling-btn:hover {
  background-color: #eceeef;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

.scheduling-btn {
  background-color: transparent;
  border: none;
}

.mobile-menu-icon {
  color: rgba(0, 0, 0, 0.5);
}

.badge-pos {
  position: absolute;
  top: 0;
  right: -5px;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>

<style lang="scss">
.el-badge__content.is-dot {
  height: 10px !important;
  width: 10px !important;
}

.el-progress__text {
  font-size: 12px !important;
}
</style>
