import { HTTP } from '@/api/http_utils'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getClientAddresses ({ commit }, [companyId, clientId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/clients/${clientId}/addresses`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getClientAddress ({ commit }, [companyId, clientId, addressId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/clients/${clientId}/addresses/${addressId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createClientAddress ({ commit }, [companyId, clientId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/clients/${clientId}/addresses`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateClientAddress ({ commit }, [companyId, clientId, addressId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/clients/${clientId}/addresses/${addressId}`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteClientAddress ({ commit }, [companyId, clientId, addressId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/clients/${clientId}/addresses/${addressId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
