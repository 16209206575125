<template>
  <li class="nav-item dropdown border-top no-md-border my-auto">
    <div class="head-link nav-link px-0" style="cursor: pointer" id="requestsDropdown" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false">
                  <span class="header-icon text-center d-none d-md-block">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="$t('REQUESTS_TOOLTIP')"
                      placement="top"
                      :show-after="1000">
                      <i class="icon-request"/>
                    </el-tooltip>
                  </span>
      <span class="d-md-none p-0 mx-2">
                    <i class="icon-request"></i>
                  </span>
      <span class="d-md-none menu-color"
            v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Requests') }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="requestsDropdown">
      <a id="demo_request_time_off" class="dropdown-item" @click="openCreateRequestModal" data-toggle="modal"
         data-target="#requestsModal" style="cursor: pointer">
        <i class="icon-plus-circle"></i>
        <span class="menu-color ml-2">{{ $t('Request Time-Off') }}</span>
      </a>

      <router-link id="demo_request_time_off_view" class="dropdown-item" :to="`/c/${companyId}/requests`">
        <i class="icon-eye"></i>
        <span class="menu-color ml-2">{{ $t('View requests') }}</span>
      </router-link>
    </div>
  </li>
</template>
<script>
export default {
  name: 'HeaderRequestsModal',
  emits: ['openCreateRequestModal'],
  props: {
    isMobile: Boolean
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    openCreateRequestModal () {
      this.$emit('openCreateRequestModal')
    }
  }
}
</script>
<style scoped>

.menu-color {
  color: #4a4a4a !important;
}

.header-icon {
  font-size: 24px;
}

.mobileItemStyle {
  padding-left: 1.25rem;
}
</style>
