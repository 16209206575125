<template>
  <nav v-if="company.is_blocked" class="navbar navbar-dark bg-danger justify-content-center text-white p-1">
    <div v-if="isEmployeeHasPermission('delete-company')">
      <div class="text-center">
        {{
          $t('All account actions are disabled because you’ve missed the payment deadline on your latest invoice. Please pay the debt to continue working with the service.')
        }}
      </div>
      <div class="text-center">
        <router-link
          :to="`/c/${company.id}#balance`"
          id="balance_link"
          class="text-white">
          <u>
            {{ $t('Company balance') }}
          </u>
        </router-link>
      </div>
    </div>
    <div v-else>
      {{
        $t('All account actions are disabled because your employer missed the payment deadline on the latest invoice. Please contact them immediately to resolve this issue.')
      }}
    </div>
  </nav>
  <nav v-if="!company.is_blocked && unpaidInvoice && isEmployeeHasPermission('create-schedule')"
       class="navbar navbar-dark bg-danger justify-content-center text-white p-1">
    <div>
      <div class="text-center">
        {{ $t('web_unpaid_invoice_alert_admin') }}
      </div>
      <div class="text-center">
        <router-link
          :to="`/c/${companyId}#balance`"
          id="balance_link"
          class="text-white">
          <u>
            {{ $t('Company balance') }}
          </u>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'HeaderWarningComponent',
  data () {
    return {
      unpaidInvoice: false,
      timer: null
    }
  },
  mounted () {
    if (this.companyId) {
      this.handle()
    }
  },
  beforeUnmount () {
    this.cancelAutoUpdate()
  },
  computed: {
    company () {
      return this.$store.getters.company
    },
    companyId () {
      return this.$store.getters.companyId
    }
  },
  watch: {
    companyId () {
      if (this.companyId) {
        this.handle()
      }
    }
  },
  methods: {
    handle () {
      this.checkUnpaidInvoices()
      this.timer = setInterval(this.checkUnpaidInvoices, 1000 * 60 * 5)
    },
    checkUnpaidInvoices () {
      this.$store.dispatch('getUnpaidedInvoices', '?status=created').then(response => {
        this.unpaidInvoice = response && response.result === true
      })
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped>

</style>
