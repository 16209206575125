<template>
  <Form ref="observer" as="div">
    <div class="row mb-2">
      <div class="col-md-4">
        <ui-color-picker
          name="colorsChoices"
            v-model="shiftInfoState.color"
            :colors="colorsChoices"
            :label="$t('Shift color')"
        />
      </div>
      <div v-if="stackedShifts.length > 1" class="col-md-8">
        <ui-radio
          name="one"
            :label="$t('UPDATE_SINGLE_SHIFT_FREEBOARD')"
            :hint="$t('UPDATE_SINGLE_SHIFT_FREEBOARD_HINT')"
            v-model="typeUpdate"
            :set-value="'one'"
        />
        <ui-radio
          name="all"
            :label="$t('UPDATE_ALL_STACKED_SHIFTS_FREEBOAR')"
            :hint="$t('UPDATE_ALL_STACKED_SHIFTS_FREEBOAR_HINT')"
            v-model="typeUpdate"
            :set-value="'all'"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Продолжительность смены-->
    <div class="row mt-2">
      <div class="col-lg-12 text-left">
        <label class="control-label font-weight-bold">{{ $t("Shift period") }}: {{ periodDuration }} {{ periodDurationHrsHuman }} {{ projectTimeZone }} <ui-hint :content="$t('SHIFT_PERIOD_DURATION_HINT')" /></label>
        <label class="control-label font-weight-bold">{{ $t("Shift duration hrs") }}: {{ duration }} {{ durationHrsHuman }} <ui-hint :content="$t('SHIFT_DURATION_HINT')" /></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 py-1">
        <ui-datetime-picker
          :label="$t('SHIFT_START')"
          name="from"
          :placeholder="$t('Select date and time')"
          v-model="startDateTime"
          :validation="{required: true}"
          :clearable="false"
        />
      </div>
      <div class="col-md-4 py-1">
        <ui-datetime-picker
          :label="$t('SHIFT_END')"
          name="to"
          :placeholder="$t('Select date and time')"
          v-model="endDateTime"
          :validation="{required: true}"
          :clearable="false"
        />
      </div>
      <div class="col-md-4 py-1" v-show="!hideBreak">
        <ui-number-input
          name="break_time"
          :label="$t('BREAK_MIN')"
          v-model="breakTime"
          :precision="0"
          :step="1"
          :min="0"
          :validation="{max_value: durationMinutes}"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-4 py-1">
        <span class="text-secondary small">{{ $t('PLANNED_SHIFT_START') }} {{plannedStartDateTime}}</span>
      </div>
      <div class="col-md-4 py-1">
        <span class="text-secondary small">{{ $t('PLANNED_SHIFT_END') }} {{plannedEndDateTime}}</span>
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Пэйрэйт и позиции-->
    <div class="row my-2 align-items-end">
      <div v-if="salaryForManager" class="col-md-6 py-1">
        <ui-number-input
          name="rate"
          :label="$t('SHIFT_PAY_RATE')"
          :hint="$t('SHIFT_PAY_RATE_HINT')"
          v-model="rate"
          :precision="2"
          :step="0.1"
          :min="0"
          :placeholder="$t('BASIC_PAY_RATE')"
        />
      </div>
      <div class="col-md-6 py-1">
        <ui-tags-select
          :label="$t('Shift positions')"
          :hint="$t('POSITION_HINT')"
          :name="'positions'"
          v-model="shiftMarks"
          :clearable="true"
          :filterable="true"
          :placeholder="$t('Select')"
          :options="projectMarks"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
          :allow-create="isEmployeeHasPermission('create-shift-admin') ||
           rolesByProjects[currentProjectId] === 'manager'"
          :disabled="!(isEmployeeHasPermission('create-shift-admin') ||
           rolesByProjects[currentProjectId] === 'manager')"
        >
          <template v-slot:default="slotProps">
            <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
      </div>
    </div>
    <el-divider class="my-2" />
    <div class="row">
      <div class="col-12 col-md-6">
        <ui-select
          :label="$t('SHIFT_EDIT_LOCATION_SELECT')"
          :hint="$t('SHIFT_EDIT_LOCATION_SELECT_HINT')"
          :name="'location'"
          v-model="shiftInfoState.location_id"
          :clearable="true"
          :placeholder="$t('Select')"
          :options="locations"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <div class="row">
      <div class="col">
        <ui-textarea-input
          name="note"
          :label="$t('SHIFT_NOTE_LABEL')"
          :hint="$t('SHIFT_NOTE_LABEL_HINT')"
          v-model="shiftInfoState.note"
          :validation="{required: false, max: 256}"
          :rows="1"
          :placeholder="$t('SHIFT_NOTE')"
        />
      </div>
      <div class="col-auto d-flex">
        <ui-checkbox
          class="mt-auto"
          name="hide_note"
          :disabled="!shiftInfoState.note"
          v-model="shiftInfoState.hide_note"
        >
          {{ $t('SHIFT_NOTE_HIDE_LABEL') }}
          <ui-hint :content="$t('SHIFT_NOTE_HIDE_LABEL_HINT')" />
        </ui-checkbox>
      </div>
    </div>
    <el-divider class="my-2" />
    <div class="row">
      <div class="col-md-12 p-1">
        <div class="d-flex">
          <button
            class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
            aria-label="Close"
            @click="save">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import moment from 'moment'
import ToastMixin from '@/mixins/ToastMixin'
import momentMixin from '@/mixins/mixinMoment'
import mixinColors from '@/mixins/mixinColors'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import errorMixin from '@/mixins/mixinApiErrors'
// import 'vue-swatches/dist/vue-swatches.css'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftFreeboardEditComponent',
  mixins: [
    ToastMixin,
    momentMixin,
    mixinColors,
    errorMixin,
    mixinColorsMarks
  ],
  data () {
    return {
      breakTime: 0,
      breakTimeChoices: [
        { id: 0, name: this.$t('None') },
        { id: 10, name: this.$t('10 min') },
        { id: 15, name: this.$t('15 min') },
        { id: 20, name: this.$t('20 min') },
        { id: 30, name: this.$t('30 min') },
        { id: 45, name: this.$t('45 min') },
        { id: 60, name: this.$t('60 min') },
        { id: 90, name: this.$t('90 min') }
      ],
      latenessStatus: 'in_time',
      latenessValue: moment().startOf('day').toDate(),
      latenessStatusesList: [
        {
          value: 'in_time',
          label: this.$i18n?.t('In time')
        },
        {
          value: 'late',
          label: this.$i18n?.t('Late')
        },
        {
          value: 'absence',
          label: this.$i18n?.t('Absence')
        }
      ],
      shiftMarks: [],
      startDateTime: null,
      endDateTime: null,
      timeoutKey: null,
      typeUpdate: 'one',
      rate: 0,
      shiftInfoState: {}
    }
  },
  props: {
    shiftInfo: Object,
    locations: Array,
    closeModal: Function,
    stackedShifts: Array
  },
  created () {
    this.shiftInfoState = this.shiftInfo
    this.rate = this.shiftInfoState.rate ? Math.round(this.shiftInfoState.rate * 100) / 100 : 0
    this.startDateTime = this.fromZoneToZone(this.shiftInfoState.time_from, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
    this.endDateTime = this.fromZoneToZone(this.shiftInfoState.time_to, 'UTC', this.$store.getters.actualTZ).format(this.backendDateTimeFormat)
    this.shiftMarks = Array.isArray(this.shiftInfoState.marks) ? this.shiftInfoState.marks : []
    this.breakTime = this.shiftInfoState.break_time ? this.shiftInfoState.break_time : 0
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId',
      projectMarks: 'projectMarks',
      preloadUsers: 'employeesIndexed'
    }),
    projectTimeZone () {
      const project = this.$store.getters.projectById(this.currentProjectId)
      const projectTz = moment(this.startDateTime).tz(project.time_zone).format('Z')
      return this.$i18n?.t('PROJECT_TIMEZONE') + ' ' + projectTz
    },
    durationMinutes () {
      return moment(this.endDateTime).diff(moment(this.startDateTime), 'minutes', true)
    },
    periodDuration () {
      // The function returns the actual duration of the shift in hours
      // without subtracting breaks, etc.
      let value = moment(this.endDateTime).diff(moment(this.startDateTime), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }
      return Math.round(value * 100) / 100
    },
    unpaidBreakTime () {
      let breakTime = 0

      // если стандартный пееррыв скрыт, то считаем модульные перерывы
      if (this.hideBreak) {
        this.shiftInfoState.breaks.forEach(element => {
          if (!element.billable) {
            // на апи не верно возвращает duration перерыва, потому считаем на лету
            breakTime += moment(element.time_to).diff(moment(element.time_from), 'minutes', true)
          }
        })
      } else if (!this.standardBreakIsPaid) {
        breakTime = this.breakTime
      }

      return breakTime
    },
    duration () {
      // Метод возвращает продолжительность дневного и ночного отрезка времени с вычетом перерывов
      const { isNightShift, nightTimeDuration, dayTimeDuration } = this.determineNightData(this.startDateTime, this.endDateTime)

      let value = 0

      if (isNightShift) {
        const nightCoefficientType = this.companySalary.night_coefficient_type !== undefined ? this.companySalary.night_coefficient_type : 'rate_increase'
        if (nightCoefficientType === 'time_recalculation') {
          // Используем множитель продолжительности ночного времени смены
          const nightCoefficient = this.companySalary.night_coefficient ? this.companySalary.night_coefficient : 0
          if (nightCoefficient > 0) {
            value = dayTimeDuration + (nightTimeDuration * nightCoefficient)
          } else {
            value = dayTimeDuration + nightTimeDuration
          }
        } else {
          value = dayTimeDuration + nightTimeDuration
        }
      } else {
        // смена не ночная, значит все время дневное, и без множителя
        value = dayTimeDuration
      }

      value = Math.round(value / 60 * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }

      return Math.round(value * 100) / 100
    },
    periodDurationHrsHuman () {
      let value = moment(this.endDateTime).diff(moment(this.startDateTime), 'hours', true)
      if (value === 0) {
        value = 24
      }

      if (value <= 0) {
        value = (24 + value)
      }

      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    durationHrsHuman () {
      const value = this.duration
      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    standardBreakIsPaid () {
      return this.companySalary.pay_breaks === undefined ? false : this.companySalary.pay_breaks
    },
    companySalary () {
      return this.$store.state.company.company.salary
    },
    usersBySchedule () {
      const usersBySchedule = {}
      const usersIds = this.schedulesById[this.shiftInfoState.schedule_id].users.map(item => item.employee_id)
      Object.keys(this.preloadUsers).forEach(key => {
        if (usersIds.includes(this.preloadUsers[key].id)) {
          usersBySchedule[key] = this.preloadUsers[key]
        }
      })
      return Object.values(usersBySchedule).sort((a, b) => {
        return a.last_name > b.last_name ? 1 : -1
      })
    },
    hideBreak () {
      return this.shiftInfoState.breaks && this.shiftInfoState.breaks.length > 0
    },
    plannedStartDateTime () {
      return moment(this.shiftInfoState.planned_from).format(this.localeDateTimeFormat)
    },
    plannedEndDateTime () {
      return moment(this.shiftInfoState.planned_to).format(this.localeDateTimeFormat)
    },
    salaryForManager () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      return this.$store.state.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.state.company.company.salary.show_salary_to_managers
    },
    shiftEmployeeId () {
      return this.shiftInfoState.employee_id
    }
  },
  watch: {
    shiftMarks (items) {
      if (Array.isArray(items) && (this.isEmployeeHasPermission('create-shift-admin') ||
        this.rolesByProjects[this.currentProjectId] === 'manager')) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.currentProjectId]).then((mark) => {
              this.shiftMarks[newIndex] = mark.id
            }).catch(() => {
              this.shiftMarks.splice(newIndex, 1)
              this.toastError(this.$i18n?.t('The name has already been taken.'))
            })
          }
        })
      }
    }
  },
  methods: {
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          if (this.typeUpdate === 'one') {
            this.handleSave()
          }
          if (this.typeUpdate === 'all') {
            this.handleSaveMultiple()
          }
        }
      })
    },
    handleSaveMultiple () {
      const params = this.handlePayload()
      params.breaks_action = 'skip'
      params.shifts = this.stackedShifts
      console.log(params)
      this.$store.dispatch('patchFreeboard', [this.currentProjectId, params]).then(response => {
        this.$eventBus.emit('refetchEventsLazy')
        this.$notify({
          title: this.$i18n?.t('Info'),
          dangerouslyUseHTMLString: true,
          message: `<div>${this.$i18n?.t('PATCHED_SUCCESSFULLY')}: ${response.shifts.length}</div>
                    <div>${this.$i18n?.t('Failed')}: ${response.errors}</div>`,
          position: this.notificationPosition,
          type: 'info'
        })
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
      // params.shifts = this.
    },
    handleSave () {
      const params = this.handlePayload()
      this.breaksAction = null
      this.$store.dispatch('patchShift', [this.shiftInfoState.id, params]).then(shift => {
        this.$eventBus.emit('refetchEventsLazy')
        this.toastSuccess(this.$i18n?.t('Shift have been successfully saved'))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((err) => {
        const errorMessage = err.response.data.message
        if (errorMessage === 'week overtime not allowed') {
          this.toastError(this.$i18n?.t('BLOCKED_BY_WEEK_OVERTIME'))
        } else if (errorMessage === 'Shift is out of schedule range') {
          const link = `/c/${this.$store.getters.companyId}/schedule/${this.shiftInfoState.schedule_id}#resizeSchedule`
          this.$notify({
            title: this.$i18n?.t('Error'),
            dangerouslyUseHTMLString: true,
            message: `<div>${this.$i18n?.t(errorMessage)}</div><div><a href="${link}">${this.$i18n?.t('LINK_TO_RESIZE_SCHEDULE')}</a></div>`,
            position: this.notificationPosition,
            type: 'error'
          })
        } else if (errorMessage === 'Breaks outside the shift') {
          this.$confirm(this.$i18n?.t('key_warning_breaks_outside_shift'), this.$i18n?.t('Warning'), {
            confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
            cancelButtonText: this.$i18n?.t('No, cancel it!!'),
            type: 'warning',
            center: true
          }).then(() => {
            this.breaksAction = 'skip'
            this.handleSave()
          }).catch(() => {})
        } else if (err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
          this.toastError(this.$i18n?.t(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]))
          document.getElementById('modalCloseButton').click()
          this.closeModal()
        } else {
          this.toastError(this.$i18n?.t(errorMessage))
          document.getElementById('modalCloseButton').click()
        }
      })
    },
    handlePayload () {
      const params = {}
      params.marks = this.shiftMarks
      params.employee_id = this.shiftInfoState.employee_id
      params.color = this.shiftInfoState.color
      params.location_id = this.shiftInfoState.location_id
      params.rate = this.rate
      params.break_time = +this.breakTime
      params.time_from = this.fromZoneToZone(moment(this.startDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      params.time_to = this.fromZoneToZone(moment(this.endDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)

      if (this.breaksAction) {
        params.breaks_action = this.breaksAction
      }
      params.note = this.shiftInfoState.note
      params.hide_note = this.shiftInfoState.hide_note
      return params
    },
    determineNightData (timeFrom, timeTo) {
      timeFrom = moment(timeFrom)
      timeTo = moment(timeTo)

      const duration = moment(timeTo).diff(moment(timeFrom), 'minutes', true)
      const breakTime = this.unpaidBreakTime

      const nightTimes = this.getNightTimes(timeFrom, timeTo)
      if (!nightTimes.length) {
        return { isNightShift: false, nightTimeDuration: 0, dayTimeDuration: duration - breakTime }
      }

      const nightShiftDefinitionType = this.companySalary.night_shift_definition_type !== undefined ? this.companySalary.night_shift_definition_type : 'full_entry'
      const nightShiftDefinitionTime = parseInt(this.companySalary.night_shift_definition_time !== undefined ? this.companySalary.night_shift_definition_time : 0)
      const nightTimeTrackingType = this.companySalary.night_time_tracking_type !== undefined ? this.companySalary.night_time_tracking_type : 'all_time'

      let isNight = false
      let nightTimeDuration = 0
      let dayTimeDuration = duration

      if (nightTimes.length) {
        // смена должна полностью входить в отрезок ночного времени
        if (nightShiftDefinitionType === 'full_entry') {
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeTo <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration
              dayTimeDuration = 0
            }
          })
        } else if (nightShiftDefinitionType === 'intersects_beginning_shift') {
          // начало смены должно входить в отрезок ночного времени
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeFrom <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration
              dayTimeDuration = 0
            }
          })
        } else {
          // общее пересечение смены с ночным временем должно составлять N часов, чтобы
          // смена считалась как ночная
          const totalNightTime = this.calculateOverlapping(timeFrom, timeTo, nightTimes)

          // продолжительность пересечения с ночным временем, с вычетом неоплачиваемых перерывов
          if (totalNightTime && totalNightTime / 60 >= nightShiftDefinitionTime) {
            isNight = true
            // смена определена как ночная, теперь нужно учитывать, вся смена идет как ночная,
            // или только пересечение
            if (nightTimeTrackingType === 'all_time') {
              nightTimeDuration = duration
              dayTimeDuration = 0
            } else {
              nightTimeDuration = totalNightTime
              dayTimeDuration = duration - nightTimeDuration
            }
          } else {
            dayTimeDuration = duration
          }
        }
      }

      // модульный перерыв
      if (this.hideBreak) {
        // так же в местах пересечения смены с ночным временем необходимо отнять время
        // пересекающихся неоплачиваемых перерывов
        let overlappingBreaksWithNightTime = 0
        let overlappingBreaksWithDayTime = 0

        this.shiftInfoState.breaks.forEach(element => {
          if (!element.billable) {
            const breakFrom = this.fromZoneToZone(moment(element.time_from), 'UTC', this.$store.getters.actualTZ)
            const breakTo = this.fromZoneToZone(moment(element.time_to), 'UTC', this.$store.getters.actualTZ)
            const breakDuration = moment(breakTo).diff(moment(breakFrom), 'minutes', true)
            const nightDuration = this.calculateOverlapping(breakFrom, breakTo, nightTimes)

            // время перерыва пересекается с ночным временем
            if (nightDuration > 0) {
              // все время перерыва в ночном отрезке
              if (breakDuration === nightDuration) {
                overlappingBreaksWithNightTime += nightDuration
              } else {
                // часть перерыва в ночном времени
                overlappingBreaksWithNightTime += nightDuration
                // и часть перерыва в дневном времени
                overlappingBreaksWithDayTime += breakDuration - nightDuration
              }
            } else {
              // время перерыва не пересекается с ночным временем,
              // все время перерыва идет в дневное время
              overlappingBreaksWithDayTime += breakDuration
            }
          }
        })
        dayTimeDuration -= overlappingBreaksWithDayTime
        nightTimeDuration -= overlappingBreaksWithNightTime
      } else if (breakTime) {
        if (nightTimeDuration > dayTimeDuration) {
          nightTimeDuration -= breakTime
        } else {
          dayTimeDuration -= breakTime
        }
      }

      return { isNightShift: isNight, nightTimeDuration: nightTimeDuration, dayTimeDuration: dayTimeDuration }
    },
    getOverlap (fromX, toX, fromY, toY) {
      let overlapFrom = fromY
      if (fromX > fromY) {
        overlapFrom = fromX
      }

      let overlapTo = toX
      if (toX > toY) {
        overlapTo = toY
      }

      return moment.duration(overlapTo.diff(overlapFrom)).asMinutes()
    },
    calculateOverlapping (start, end, ranges) {
      let overlaps = 0

      // order ranges by start time
      ranges = ranges.sort((a, b) => a[0].unix() - b[0].unix())

      for (let i = 0; i < ranges.length; i++) {
        const rangeStart = ranges[i][0]
        const rangeEnd = ranges[i][1]
        if (rangeEnd.isBefore(start) || rangeStart.isAfter(end)) {
          continue
        }
        overlaps += this.getOverlap(start, end, rangeStart, rangeEnd)
      }
      return overlaps
    },
    getNightTimes (timeFrom, timeTo) {
      // В любом случаи от timeFrom минусуем 1 день так как одна смена
      // может 2-а раза пересекаться с ночным времен и нужно проверить все пересечения.
      // К примеру в настройка компании ночное время с 22:00 до 00:08,
      // смена 25.07.2023 02:00 до 25.07.2023 23:30, в итоге смена 2-а раза пересекается
      // с ночным временем, а именно:
      //  - c 25.07.2023 02:00 до 25.07.2023 08:00,
      //  - c 25.07.2023 22:00 до 25.07.2023 23:30
      const range = moment.range(timeFrom.clone().add(-1, 'days'), timeTo.clone().add(1, 'days'))

      // если не указаны сеттинги возвращаем пустой массив
      if (!this.companySalary.night_start || !this.companySalary.night_end) {
        return []
      }

      const nightFrom = moment(this.companySalary.night_start, 'H:mm')
      const nightTo = moment(this.companySalary.night_end, 'H:mm')

      // форматируем время по каждому дню
      const times = Array.from(range.by('days')).map((m, index) => {
        const start = m.clone().set('hour', nightFrom.hour()).set('minute', nightFrom.minute())
        const end = m.clone().set('hour', nightTo.hour()).set('minute', nightTo.minute())

        // переходящая смена, добавляем +1 день к дате окончания
        if (end < start) {
          end.add(1, 'days')
        }
        return [start, end]
      })

      return times
    }
  }
}
</script>

<style scoped>
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 30px;
    height: 30px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 14px;
    left: 11px;
    top: 4px;
  }
</style>
