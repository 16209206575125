<template>
  <modal-component :id="'TodoFormModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
      <template #header>
        <h5 class="modal-title">{{form.id ? $t('Update todo') : $t('Create todo')}}</h5>
      </template>
      <template #body>
        <Form ref="observer" as="div">
          <template v-if="isEmployeeHasPermission('update-todo-content')">
            <div class="row">
              <div class="col">
                <ui-select
                  :label="$t('Employee')"
                  name="employee_id"
                  v-model="form.employee_id"
                  class="w-100"
                  :clearable="true"
                  :options="employees"
                  :validation="{required: true}"
                  :key-name="'id'"
                  :value-name="'id'"
                  :label-name="'name'"
                  :disabled="isDisabled"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ui-datetime-picker
                  :label="$t('DEADLINE_DATE')"
                  name="deadline"
                  :placeholder="$t('Select deadline date')"
                  v-model="form.deadline_date"
                  :validation="{required: false}"
                  :clearable="true"
                  :disabled="isDisabled"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ui-textarea-input
                  :label="$t('Content')"
                  name="content"
                  v-model="form.content"
                  class="w-100"
                  :validation="{required: true}"
                  :rows="2"
                  :disabled="isDisabled"
                />
              </div>
            </div>
          </template>
          <template v-if="form.id && (!isEmployeeHasPermission('update-todo-content') || form.employee_id === currentEmployeeId)">
            <ui-select
              id="status"
              name="status_id"
              :label="$t('Status')"
              v-model="form.status"
              class="w-100"
              :options="statuses"
              :clearable="false"
              :validation="{ required: true }"
            />
          </template>
        </Form>
      </template>
      <template #footer>
        <div class="row">
          <div class="col py-2 text-right">
            <button
              @click="closeModal"
              class="btn btn-primary btn-circle shadow-sm mx-1">
              {{ $t('Close') }}
            </button>
            <button
              @click="save"
              class="btn btn-success btn-circle shadow-sm mx-1"
            >
              {{ $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
      </template>
    </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'

export default {
  components: { ModalComponent },
  mixins: [momentMixin],
  name: 'TodoFormModal',
  data () {
    return {
      form: {},
      statuses: [
        {
          id: 'new',
          name: this.$t('new')
        },
        {
          id: 'completed',
          name: this.$t('completed')
        }
      ]
    }
  },
  emits: ['init'],
  props: {
    formData: Object
  },
  created () {
    this.form = { ...this.formData }
  },
  computed: {
    isDisabled () {
      return this.formData.id && this.formData.status === 'completed'
    },
    employees () {
      return this.$store.getters.employees.map(employee => {
        return {
          id: employee.id,
          name: employee.full_name
        }
      })
    },
    currentEmployeeId () {
      return this.$store.getters.company.employee_id
    }
  },
  watch: {
    formData () {
      this.form = { ...this.formData }
      if (!this.form.id) {
        this.$refs.observer.resetForm()
      }
      // this.$refs.observer.resetForm()
    }
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          if (this.form.id) {
            this.handleEdit()
          } else {
            this.handleCreate()
          }
        }
      }).catch(() => {
      })
    },
    handleEdit () {
      let payload
      if (this.isEmployeeHasPermission('update-todo-content')) {
        payload = { ...this.form }
        if (payload.deadline_date) {
          payload.deadline_date = this.fromZoneToZone(payload.deadline_date, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
        }
      } else {
        payload = {
          status: this.form.status
        }
      }

      this.$store.dispatch('updateTodo', [this.companyId, this.form.id, payload]).then(() => {
        this.toastSuccess(this.$t('TODO_UPDATED'))
        this.$emit('init')
        this.closeModal()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    handleCreate () {
      const payload = { ...this.form }
      if (payload.deadline_date) {
        payload.deadline_date = this.fromZoneToZone(payload.deadline_date, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      }
      this.$store.dispatch('createTodo', [this.companyId, payload]).then(() => {
        this.toastSuccess(this.$t('TODO_CREATED'))
        this.$emit('init')
        this.closeModal()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  }
}
</script>
<style scoped>

</style>
