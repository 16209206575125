<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <template v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Checklists") }}
          <ui-hint :content="$t('CHECKLIST_HINTS')" />
        </span>
      </div>
      <div v-if="list.length > 0" class="card card-small">
        <div class="card-body table-responsive">
          <div class="row py-2">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill ml-4 mr-2" type="button" @click="addChecklist">
                  {{ $t('Create checklist') }}
                </button>
              </div>
            </div>
          </div>
          <table class="table table-hover">
            <thead class="thead-light">
            <tr>
              <th class="text-left">{{ $t('CHECKLISTS_ITEMS_TITLE') }}</th>
              <th class="text-center">{{ $t('CHECKLISTS_ITEMS_COUNT') }}</th>
              <th class="text-center">{{ $t('CHECKLISTS_DEFAULT') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="checklist in list" :key="checklist.id">
              <tr>
                <td>
                  {{checklist.title}}
                </td>
                <td class="text-center">
                  {{checklist.items.length}}
                </td>
                <td class="text-center">
                  {{ checklist.is_default ? $t('Yes') : $t('No') }}
                </td>
                <td class="text-center">
                  <router-link :to="`/c/${companyId}/checklist/${checklist.id}`">
                    <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                  </router-link>
                  <i class="icon-trash btn-icon text-danger"
                     @click="deleteChecklist(checklist.id)"
                     role="button">
                  </i>
                </td>
              </tr>
            </template>
            <tr></tr>
            </tbody>
          </table>
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
            >
          </el-pagination>
        </div>
      </div>
      <div v-else class="card">
        <div class="card-body text-center">
          <img class="img-fluid" style="max-height: 350px" src="../../../../public/static/images/App/Splash_1_01.png"
               alt=""/>
          <h5 class="m-3">
            {{$t('NO_CHECKLISTS')}}
          </h5>
          <div>
            <button class="btn btn-primary btn-md rounded-pill shadow-sm" type="button" @click="addChecklist">
              {{ $t('Create checklist') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import { sortArrOfObjectsByPropAsc } from '@/utils/sort'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Checklists',
  components: { DummyPaymentRequired },
  mixins: [TasksAccessMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      showItem: {},
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.$router.replace({ query: { page: val } })
      this.init()
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      if (!this.isEmployeeHasPermission('create-checklists')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      const page = '?page=' + this.pagination.currentPage
      this.$store.dispatch('getChecklists', [this.companyId, page]).then((response) => {
        this.list = sortArrOfObjectsByPropAsc(response.data, 'title')
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        if (response.current_page > response.last_page) {
          this.$router.replace({ query: { page: response.last_page } })
        }
        this.list.forEach((item, index) => {
          this.showItem[index] = false
        })
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addChecklist () {
      this.$router.push(`/c/${this.companyId}/checklist`)
    },
    deleteChecklist (checklistId) {
      this.$confirm(this.$t('DELETE_CHECKLIST_WARNING'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteChecklist', [this.companyId, checklistId]).then(() => {
          this.toastSuccess(this.$t('Delete completed'))
          this.init()
        }).catch((err) => {
          let message = this.$t(err.response.data.message)
          if (err.response.data.errors.related_task) {
            message = this.$t('CHECKLIST_HAS_RELATED_TASK')
          }
          this.toastError(message)
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>

<style scoped>

</style>
