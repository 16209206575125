<template>
  <li class="nav-item d-none d-md-block">
    <router-link
      :to="`/c/${companyId}/location-control`"
      class="nav-link d-none d-md-block my-auto px-0">
      <button id="locationControl" type="button" class="btn btn-light menu-color">{{ $t('LOCATION_CONTROL_LINK') }}</button>
    </router-link>
  </li>
  <li class="nav-item d-md-none border-top py-2">
    <router-link
      :to="`/c/${companyId}/location-control`"
      class="d-md-none px-0">
                  <span class="d-md-none mx-2 menu-color">
                    <i class="icon-map-pin" />
                  </span>
      <span class="d-md-none menu-color" :class="{ mobileItemStyle: isMobile }">{{ $t('LOCATION_CONTROL_LINK') }}
                  </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: 'HeaderMapComponent',
  props: {
    isMobile: Boolean
  }
}
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all');

.menu-color {
  color: #4a4a4a !important;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle {
  border-radius: 0px 7px 7px 0px !important;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

#locationControl {
  border-radius: 7px !important;
  background-color: transparent;
  border: none;
}

#locationControl:hover {
  background-color: #eceeef;
}

.mobileItemStyle {
  padding-left: 1.25rem;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>
