<template>
  <div v-if="dummy">
    <dummy-payment-required
      @activate="init()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
  </div>
  <div v-else-if="loaded">
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t("Files") }}</span>
    </div>
    <div class="row">
      <div class="col-md-12 p-2">
        <ui-file-upload
          name='file'
          :upload="upload"
          :auto-upload="true"
          :multiple="false"
          :limit="1"
          @onSuccess="success"
          :slotFile="true"
          :show-file-list="false"
        />
      </div>
    </div>
    <div class="row mt-4 card">
      <div class="col-md-12 p-2">
        <div class="row">
          <div class="col-md-12 font-weight-bold">
            {{$t('Company')}}: <span class="text-primary">{{companyName}}</span>
          </div>
        </div>
        <template v-if="companyFiles.length > 0">
          <!-- <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6"> -->
            <div class="file-block p-2" v-for="file in companyFiles" :key="file.key">
              <!-- <div class="h-100 d-flex flex-column justify-content-between"> -->
                <div class="d-flex justify-content-end">
                  <i
                    v-if="file.mime_type.slice(0, 5) === 'image'"
                    class="icon-eye text-primary m-1"
                    @click="handlePictureCardPreview(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    class="icon-download text-primary m-1"
                    @click="handleDownloadObject(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    v-if="isDeleteAvailable(file)"
                    class="icon-trash text-danger m-1"
                    @click="deleteFile(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                </div>
                <div>
                  <div
                    v-if="file.mime_type && file.mime_type.slice(0, 5) === 'image'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div
                    v-else-if="file.mime_type === 'application/pdf'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div v-else
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                </div>
                <div class="text-truncate align-bottom mt-2">{{file.fullname}}</div>
              <!-- </div> -->
            </div>
            <el-dialog v-model="dialogVisible">
              <img width="100%" :src="dialogUrl">
            </el-dialog>
          <!-- </div> -->
        </template>
        <template v-else>
          <div class="text-center p-5">
            {{$t('No files found')}}
          </div>
        </template>
      </div>
    </div>
    <!-- end company files -->
    <!-- start unsharedFiles -->
    <div class="row mt-4">
      <div class="col-md-12 p-2">
        <template v-if="unsharedFiles.length > 0">
          <!-- <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6"> -->
            <div class="file-block p-2" v-for="file in unsharedFiles" :key="file.key">
              <!-- <div class="h-100 d-flex flex-column justify-content-between"> -->
                <div class="d-flex justify-content-end">
                  <el-tooltip :content="$t('Share files')" placement="top" effect="light">
                    <i
                      class="icon-share2 text-primary m-1"
                      @click="shareFile(file)"
                      style="cursor: pointer; font-size: 19px"
                    ></i>
                  </el-tooltip>
                  <i
                    v-if="file.mime_type.slice(0, 5) === 'image'"
                    class="icon-eye text-primary m-1"
                    @click="handlePictureCardPreview(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    class="icon-download text-primary m-1"
                    @click="handleDownloadObject(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    v-if="isDeleteAvailable(file)"
                    class="icon-trash text-danger m-1"
                    @click="deleteFile(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                </div>
                <div>
                  <div
                    v-if="file.mime_type && file.mime_type.slice(0, 5) === 'image'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div
                    v-else-if="file.mime_type === 'application/pdf'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div v-else
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                </div>
                <div class="text-truncate align-bottom mt-2">{{file.fullname}}</div>
              <!-- </div> -->
            </div>
            <el-dialog v-model="dialogVisible">
              <img width="100%" :src="dialogUrl">
            </el-dialog>
          <!-- </div> -->
        </template>
        <template v-else>
          <div class="text-center p-5">
            {{$t('No files found')}}
          </div>
        </template>
      </div>
    </div>
    <!-- end unsharedFiles -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'Files',
  components: {
    DummyPaymentRequired: DummyPaymentRequired
  },
  mixins: [mixinDummy],
  data () {
    return {
      files: [],
      unsharedFiles: [],
      companyFiles: [],
      display: {
        status: false,
        name: '',
        button: ''
      },
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      dummy: false,
      loaded: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      upload: {
        url: process.env.VUE_APP_ROOT_API + 'companies/' + this.$store.getters.companyId + '/file/upload',
        headers: {
          Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
        }
      },
      fileLimit: 10240000
    }
  },
  beforeMount () {
    if (!this.loaded && this.companyId) {
      this.init()
    }
  },
  computed: {
    userId () {
      return this.$store.getters.profileId
    },
    companyId () {
      // console.log(1, this.$store.getters.companyId)
      return this.$store.getters.companyId
    },
    apiKey () {
      return localStorage.getItem('api_key')
    },
    routeId () {
      return this.$route.params.id
    },
    companyName () {
      return this.$store.getters.company.name
    },
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    routeId () {
      if (!this.loaded && this.companyId) {
        this.init()
      }
    },
    companyId () {
      if (!this.loaded && this.companyId) {
        this.init()
      }
      if (this.companyId) {
        this.dropzoneOptions.url = process.env.VUE_APP_ROOT_API + 'companies/' + this.companyId + '/file/upload'
      }
    }
  },
  methods: {
    init () {
      this.$store.dispatch('isModuleActive', [this.companyId, process.env.VUE_APP_COMPANY_FILES_ID]).then(resolve => {
        this.dummy = false
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.$router.push('dashboard')
        }
      })
      this.$store.dispatch('getFiles').then(files => {
        this.files = files
        this.loaded = true
        this.companyFiles = this.files.filter(item => {
          const company = item.shared.filter(share => {
            return +share.company_id === +this.companyId
          })
          return company.length > 0
        })
        const companyFilesIds = this.companyFiles.map(item => {
          return +item.id
        })
        this.unsharedFiles = this.files.filter(item => {
          return !companyFilesIds.includes(+item.id)
        })
      })
      // this.$refs.myVueDropzone.setOption('url', process.env.VUE_APP_ROOT_API + 'companies/' + this.companyId + '/file/upload')
    },
    success (file) {
      this.files = []
      this.unsharedFiles = []
      this.companyFiles = []
      this.init()
    },
    deleteFile (file) {
      this.$store.dispatch('delFile', [file.id]).then(() => {
        this.init()
      })
    },
    shareFile (file) {
      this.$store.dispatch('shareFile', [this.companyId, { file_id: file.id }]).then(() => {
        this.init()
      })
    },
    handlePictureCardPreview (file) {
      this.dialogUrl = file.url
      this.dialogVisible = true
      this.dialogType = file.mime_type
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    isDeleteAvailable (file) {
      const createdAt = file.created_at
      let allowDelete = true
      let shared = file.shared.filter(item => item.company_id === this.companyId)
      if (shared.length > 0) {
        shared = shared[0]
        allowDelete = shared.employee_id === shared.uploader_id
      }
      return moment().isSameOrBefore(moment(createdAt).add(10, 'Minutes')) && allowDelete
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../public/static/images/App/file.png')
      }
    }
  }
}
</script>

<style>
  #fileDrop:hover {
    background-color: white;
    border: 1px dashed #00b7f1;
  }
  #fileDrop {
    border: 1px dashed #e5e5e5;
    background-color: transparent;
    border-radius: 13px;
  }
  #fileDrop .dz-preview, .dz-details{
    background-color: transparent!important;
  }
  #fileDrop .dz-error-message{
    top: 130px!important;
    border-radius: 0;
  }
  #fileDrop .dz-error-message::after{
    border-top: 0px;
  }
  .preview {
    width: 160px;
    height: 100px!important;
  }
  .file-block {
    display: inline-block;
    width: 190px!important;

  }
  .uploaded-file {
    width: 190px;
    height: 150px!important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
