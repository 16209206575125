<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{$t('Time off Request')}}</div>
      <div class="ml-md-4" style="line-height: 27px">
        &nbsp;<span>{{ $t('Company') }}</span><span class="ml-2 font-weight-bold">{{ companyName }}</span>
      </div>
    </div>
    <div v-if="request.vacation_warning" class="row ml-0 pl-0 col-12">
      <el-alert
        class="mb-3"
        :closable="false"
        :show-icon="true"
        :title="$t(request.vacation_warning)"
        type="warning" />
    </div>
    <div class="row mt-3 ml-0 pl-0">
        <span>{{ $t('Type') }}</span>
        <span class="ml-1 font-weight-bold">{{displayType(request.type_time_off)}} <span v-if="request.reason">{{ request.reason.title }}</span></span>
        <span class="ml-1 font-weight-bold">
          ({{displayPaid(request.is_paid)}},
          <span class="ml-1 font-weight-bold">
            <span v-if="request.duration === 'all_day'"> {{$i18n.t('days')}}: {{Math.round(request.duration_time / 60.0 / 24.0 * 100) / 100}}</span>
            <span v-else>{{$i18n.t('hours')}}: {{ Math.round(request.duration_time / 60.0 * 100) / 100}}</span>
          </span>)
        </span>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('By user') }}</span>
        <span class="visible-md-inline visible-lg-inline ml-3">
          <avatar v-if="userName"
            class="rounded-circle d-inline-block font-weight-normal"
            :width="35"
            :userId="request.profile_id"
            :borderRadius="50"
            :placeholder="false"
            :mini="true"
            :cache="false"
            :userName="userName">
          </avatar>
        </span>
        <span class="ml-2 font-weight-bold">{{userName}}</span>
        <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <span>{{ $t('Period:') }}</span>
      <template v-if="request.duration === 'all_day'">
        <span class="ml-1 font-weight-bold">{{ $t('all day') }}</span>
      </template>
      <template v-else>
        <span class="ml-1 font-weight-bold">{{ $t('part of day') }}</span>
      </template>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <span>{{ $t('From:') }}</span>
      <span class="ml-1 font-weight-bold mr-2">
        {{!isFullDayDisplayRequest ? toTimeZone(request.date_from).format(localeDateTimeFormat) : toTimeZone(request.date_from).format(localeDateFormat)}}
      </span>
      <span>{{ $t('To:') }}</span>
      <span class="ml-1 font-weight-bold">
        {{!isFullDayDisplayRequest ? toTimeZone(request.date_to).format(localeDateTimeFormat) : toTimeZone(request.date_to).add(-1, 'day').format(localeDateFormat)}}
      </span>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1 font-weight-bold">{{toTimeZone(request.created_at).format(localeDateTimeFormat)}}</span>
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0" v-if="userRequestState.status === 'new' &&
     (isEmployeeHasPermission('create-request-admin') ||
      isEmployeeHasPermission('create-request-manager'))">
      <div class="col">
        <ui-radio
          name="skip"
          class="mt-2"
          :label="$t('MOVE_SHIFTS_ACTION_SKIP')"
          v-model="move_shifts_action"
          :set-value="'skip'"
        />
        <ui-radio
          name="move"
          :label="$t('MOVE_SHIFTS_ACTION_MOVE')"
          v-model="move_shifts_action"
          :set-value="'move'"
        />
        <ui-radio
          name="delete"
          :label="$t('MOVE_SHIFTS_ACTION_DELETE')"
          v-model="move_shifts_action"
          :set-value="'delete'"
        />
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0" v-if="request.file !== null">
      <div class="col mt-2">
        <div class="w-100"><span>{{ $t('Attachments:') }}</span></div>
        <div class="file-block p-2" v-if="request.file">
            <div class="d-flex justify-content-end">
              <i
                v-if="request.file.mime_type.slice(0, 5) === 'image'"
                class="icon-eye text-primary m-1"
                @click="handlePictureCardPreview(request.file)"
                style="cursor: pointer; font-size: 20px"
              >
              </i>
              <i
                class="icon-download text-primary m-1"
                @click="handleDownloadObject(request.file)"
                style="cursor: pointer; font-size: 20px"
              >
              </i>
            </div>
            <div>
              <div
                v-if="request.file.mime_type && request.file.mime_type.slice(0, 5) === 'image'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(request.file)+')'}"
              ></div>
              <div
                v-else-if="request.file.mime_type === 'application/pdf'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(request.file)+')'}"
              ></div>
              <div v-else
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(request.file)+')'}"
              ></div>
            </div>
            <div class="text-truncate align-bottom mt-2">{{request.file.fullname}}</div>
          <!-- </div> -->
        </div>
        <el-dialog v-model="dialogVisible">
          <img width="100%" :src="dialogUrl">
        </el-dialog>
      </div>
    </div>
    <div class="container-fluid mt-3 col-md-12">
      <div class="row mt-3 ml-0 pl-0 b-bottom pb-5 align-middle">
        <button
          v-if="request.status === 'new' &&
           (isEmployeeHasPermission('create-request-admin') ||
           isEmployeeHasPermission('create-request-manager'))"
          class="btn btn-sm btn-circle btn-success float-right btn-height pt-0 pb-0 mr-4 shadow-sm"
          @click="confirm">
          {{ $t('Confirm') }}
        </button>
        <button
          v-if="request.status === 'new' || request.status === 'confirmed'"
          class="btn btn-sm btn-circle btn-danger float-right btn-height pt-0 pb-0 shadow-sm"
          @click="runSwal">
          {{ $t('Cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'

export default {
  name: 'TimeOff',
  props: ['userRequest', 'employee'],
  components: { Avatar },
  mixins: [momentMixin, requestMixin],
  data () {
    return {
      move_shifts_action: 'skip',
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      userRequestState: {}
    }
  },
  created () {
    this.userRequestState = this.userRequest
  },
  computed: {
    companyName () {
      return this.$store.getters.company.name
    },
    userName () {
      return this.userRequestState.employee_name
    },
    request () {
      return this.userRequestState
    },
    isFullDayDisplayRequest () {
      if (this.userRequestState.duration === 'all_day') {
        const from = this.toTimeZone(this.userRequestState.date_from)
        const to = this.toTimeZone(this.userRequestState.date_to)
        const diff = to.diff(from, 'days')
        if (!(diff >= 1 && to.format(this.backendTimeFormat) === '00:00')) {
          return false
        }

        return true
      }

      return false
    }
  },
  methods: {
    displayType (type) {
      if (type === 'sick') {
        return this.$i18n?.t('Sick day')
      } else if (type === 'vacation') {
        return this.$i18n?.t('Vacation')
      } else if (type === 'holiday') {
        return this.$i18n?.t('Holiday')
      } else if (type === 'exchange') {
        return this.$i18n?.t('Exchange')
      } else if (type === 'move_to_freeboard') {
        return this.$i18n?.t('Move to Free Board')
      } else if (type === 'move_to_user') {
        return this.$i18n?.t('Move to user')
      } else return this.$i18n?.t(type)
    },
    displayPaid (isPaid) {
      if (isPaid) {
        return this.$i18n?.t('Paid')
      } else {
        return this.$i18n?.t('Unpaid')
      }
    },
    handlePictureCardPreview (file) {
      this.dialogUrl = file.url
      this.dialogVisible = true
      this.dialogType = file.mime_type
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../../public/static/images/App/file.png')
      }
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    confirm () {
      const data = {
        subtype: this.userRequestState.subtype,
        status: 'confirmed',
        message: null,
        move_shifts_action: this.move_shifts_action
      }
      // console.log(this.userRequest)
      this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.userRequestState.id, data]).then(response => {
        if (!this.isEmployeeHasPermission('delete-request')) {
          this.userRequestState.user_confirm = true
        }
        if (this.isEmployeeHasPermission('create-request-admin') ||
          this.isEmployeeHasPermission('create-request-manager')) {
          this.userRequestState.manager_confirm = true
        }
        this.userRequestState.status = response.status
      }).catch(err => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    },
    cancel (reason) {
      const data = {
        subtype: this.userRequestState.subtype,
        status: 'declined',
        message: reason,
        move_shifts_action: this.move_shifts_action
      }
      this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.userRequestState.id, data]).then(response => {
        this.userRequestState.status = response.status
      })
    },
    runSwal () {
      this.$prompt(this.$i18n?.t('State a reason'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, do it!'),
        cancelButtonText: this.$i18n?.t('No, cancel please'),
        inputPattern: /.{5,}/,
        inputErrorMessage: this.$i18n?.t('Enter reason')
      }).then(({ value }) => {
        this.cancel(value)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
