<template>
  <div class="row my-1">
    <div class="col-3 my-1">
      <ui-text-input
          :name="name + '__item_title'"
          :label="$t('CHECKLIST_ITEM_TITLE')"
          v-model="itemState.title"
          :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
      />
    </div>
    <div class="col-3 my-1">
      <ui-select
        :name="name + '__types'"
        :label="$t('CHECKLIST_ITEM_TYPE')"
        v-model="itemState.field_type"
        :clearable="false"
        :filterable="false"
        class="w-100"
        :options="typeChoices"
        :key-name="'id'"
        :label-name="'name'"
        :value-name="'id'"
        :validation="{ required: true }"
      />
    </div>
    <div class="col my-1">
      <label class="control-label font-weight-bold">{{$t('CHECKLIST_ITEM_OPTIONS')}}</label>
      <div v-if="['select', 'multi_select'].includes(itemState.field_type)">
        <el-tag
          :name="name + '__type_options'"
          :key="option"
          v-for="option in itemState.type_options"
          closable
          class="my-1"
          effect="plain"
          :disable-transitions="false"
          @close="handleClose(option)">
          {{option}}
        </el-tag>
        <template v-if="inputVisible">
          <div class="form-inline">
            <Form ref="observer" as="div">
              <ui-text-input
                :name="name + '__multiselect_item_name'"
                v-model="inputValue"
                :validation="{required: true, max: 60, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
                ref="saveTagInput"
                :refOuter="'saveTagInput'"
                @keyup.enter="handleInputConfirm"
              />
            </Form>
            <i class="icon-check btn-icon text-success mx-1 my-auto"
               @click="handleInputConfirm"
               role="button">
            </i>
            <i class="icon-x btn-icon text-danger mx-1 my-auto"
               @click="handleInputCancel"
               role="button">
            </i>
          </div>
        </template>
        <el-button v-else class="my-1" size="small" @click="showInput">{{$t('ITEM_NEW_OPTION')}}</el-button>
      </div>
    </div>
    <div v-if="showSearchable" class="col-auto my-1 text-center">
      <ui-tooltip>
        <template #hint>
          {{$t('CHECKLIST_ITEM_SEARCHABLE')}}
        </template>
        <template #inner-html>
          <el-icon :size="18"><Search/></el-icon>
          <i class="Search btn-icon mb-2" />
        </template>
      </ui-tooltip>
      <div>
        <ui-checkbox
          :name="name + '__searchable'"
          :disabled="itemState.field_type === 'checkbox'"
          v-model="itemState.searchable"
        />
      </div>
    </div>
    <div class="col-auto my-1 text-center">
      <ui-tooltip>
        <template #hint>
          {{$t('CHECKLIST_ITEM_REQUIRED')}}
        </template>
        <template #inner-html>
          <el-icon :size="18" color="red"><Flag/></el-icon>
        </template>
      </ui-tooltip>
      <div>
        <ui-checkbox
          :name="name + '__required'"
          v-model="itemState.required"
        />
      </div>
    </div>
    <div v-if="showItemConfig" class="col-auto my-1 text-center">
      <ui-tooltip>
        <template #hint>
          {{$t('CHECKLIST_ITEM_SHOW_IN_GRID')}}
        </template>
        <template #inner-html>
          <i class="icon-eye btn-icon" />
        </template>
      </ui-tooltip>
      <div>
        <ui-checkbox
          :name="name + '__show_in_grid'"
          v-model="itemState.show_in_grid"
        />
      </div>
    </div>
    <div class="col-auto m-1 d-flex text-center">
      <i class="icon-trash btn-icon text-danger my-auto"
         @click="deleteItem()"
         role="button">
      </i>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Item',
  mixins: [errorMixin],
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      typeChoices: [
        {
          id: 'text',
          name: this.$t('text')
        },
        {
          id: 'number',
          name: this.$t('number')
        },
        {
          id: 'checkbox',
          name: this.$t('checkbox')
        },
        {
          id: 'select',
          name: this.$t('select')
        },
        {
          id: 'multi_select',
          name: this.$t('multi-select')
        },
        {
          id: 'date_range_picker',
          name: this.$t('date-range')
        },
        {
          id: 'date_picker',
          name: this.$t('date-picker')
        },
        {
          id: 'file',
          name: this.$t('file-picker')
        }
      ],
      itemState: {}
    }
  },
  props: {
    item: Object,
    showItemConfig: Boolean,
    showSearchable: Boolean,
    toHide: Array,
    name: String
  },
  created () {
    this.itemState = this.item
    if (this.toHide) {
      this.typeChoices = this.typeChoices.filter(item => !this.toHide.includes(item.id))
    }
  },
  methods: {
    deleteItem () {
      this.$emit('deleteItem')
    },
    handleClose (tag) {
      this.itemState.type_options.splice(this.itemState.type_options.indexOf(tag), 1)
    },

    showInput () {
      this.inputVisible = true
      // this.$nextTick(_ => {
      //   this.$refs.saveTagInput.$refs.refUiInput.focus()
      // })
    },
    handleInputConfirm () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const inputValue = this.inputValue
          if (inputValue) {
            if (!this.itemState.type_options) this.itemState.type_options = []
            this.itemState.type_options.push(inputValue)
          }
          this.inputVisible = false
          this.inputValue = ''
        }
      })
    },
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
      // this.errors.clear()
    },
    typeChange (type) {
      if (!['select', 'multi_select'].includes(type) || !this.itemState.type_options) {
        this.itemState.type_options = []
      }
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
