<template>
  <div>
    <TaskModal
      v-if="taskModalShow && taskModalData"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      @closeTaskModal="closeTaskModal"
      @deleteSubtask="$emit('reload')"
      @taskUpdated="$emit('reload')"
      :title="'TASK_EVENT_MODAL'"
    />
    <el-table
      ref="taskTable"
      class="table text-break"
      header-row-class-name="thead-light"
      header-cell-class-name="text-break"
      :data="list || []"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="showBulkMenu"
        type="selection"
        width="55"
      />
      <el-table-column
        :label="$t('title')"
        cell-class-name="text-break"
        label-class-name="text-break"
        prop="title">
      </el-table-column>
      <el-table-column
        :label="$t('description')"
        cell-class-name="text-break"
        label-class-name="text-break"
        prop="description">
        <template #default="scope">
          <div class="text-break">
            {{scope.row.description}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Client name')"
        cell-class-name="text-break"
        label-class-name="text-break"
        prop="description">
        <template #default="scope">
          <template v-if="isBank">
            <div v-if="scope.row.client" class="text-break">
              {{scope.row.client.name}}
            </div>
          </template>
          <template v-else>
            <div v-if="scope.row.client" class="text-break">
              <router-link :to="'/c/'+ companyId + '/clients/' + scope.row.client.id">
                {{scope.row.client.name}}
              </router-link>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Client address')"
        cell-class-name="text-break"
        label-class-name="text-break"
        width="300"
        prop="description">
        <template #default="scope">
          <div v-if="scope.row.client_address" class="text-break">
            {{scope.row.client_address.address}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Employee')"
        cell-class-name="text-break"
        label-class-name="text-break"
      >
        <template #default="scope">
          <div v-if="scope.row.employee">
            {{ scope.row.employee.full_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('PLANNED_TIME')"
        cell-class-name="text-break"
        label-class-name="text-break"
        prop="lead_time">
        <template #default="scope">
          {{ scope.row.scheduled_time ? toTimeZone(scope.row.scheduled_time).format(localeDateTimeFormat) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Status')"
        cell-class-name="text-break"
        label-class-name="text-break"
        prop="status"
        :filters="statusesData"
        :filter-method="statusHandler"
      >
        <template #default="scope">
          <div v-if="scope.row.status">
            <span class="project-color mx-1" v-bind:style="{ backgroundColor: scope.row.logs[scope.row.logs.length - 1].status.color }"></span>
            {{ scope.row.status }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('FINISH_DATE')"
        cell-class-name="text-break"
        label-class-name="text-break"
      >
        <template #default="scope">
          <div v-if="scope.row.logs && scope.row.logs.length > 0">
            {{ scope.row.logs[scope.row.logs.length - 1].status.check_out ? toTimeZone(scope.row.logs[scope.row.logs.length - 1].created_at).format(localeDateTimeFormat) : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        cell-class-name="text-break"
        label-class-name="text-break"
        width="200"
        align="right">
        <template #header v-if="showBulkMenu && selection.length > 0">
<!--          <i-->
<!--            class="icon-edit btn-icon text-primary mx-1 my-auto"-->
<!--            @click="bulkUpdate">-->
<!--          </i>-->
          <i
            class="icon-trash btn-icon text-danger mx-1 my-auto"
            @click="bulkDelete">
          </i>
        </template>
        <template #default="scope">
          <template v-if="isBank">
            <el-tooltip :content="$t('View')" placement="top" effect="light">
              <i
                class="icon-eye btn-icon text-primary mx-1"
                @click="handleBankView(scope.$index, scope.row)">
              </i>
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip :content="$t('View')" placement="top" effect="light">
              <i
                class="icon-eye btn-icon text-primary mx-1"
                @click="handleView(scope.$index, scope.row)">
              </i>
            </el-tooltip>
            <el-tooltip :content="$t('Edit')" placement="top" effect="light">
              <i
                data-toggle="modal" data-target="#ClickTaskModal"
                class="icon-pencil btn-icon text-primary mx-1"
                @click="handleEdit(scope.row)">
              </i>
            </el-tooltip>
            <el-tooltip :content="$t('Delete')" placement="top" effect="light">
              <i
                class="icon-trash btn-icon text-danger mx-1"
                @click="handleDelete(scope.$index, scope.row)">
              </i>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'TasksTableComponent',
  mixins: [momentMixin, ToastMixin],
  components: { TaskModal },
  data () {
    return {
      taskModalShow: false,
      taskModalData: null,
      selection: []
    }
  },
  emits: ['list', 'reload', 'selection'],
  props: {
    isBank: Boolean,
    statusesData: Array,
    showBulkMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    list () {
      const tasks = JSON.parse(JSON.stringify(this.$store.getters.tasks))
      tasks.forEach(task => {
        if (task.logs && task.logs.length > 0) {
          task.status = task.logs[task.logs.length - 1].status.title
        } else {
          task.status = null
        }
      })
      // console.log(123, tasks)
      this.$emit('list', tasks)
      return tasks
    }
  },
  methods: {
    handleSelectionChange (selected) {
      this.selection = []
      selected.forEach(task => {
        this.selection.push(task.id)
      })
      this.$emit('selection', this.selection)
    },
    statusHandler (value, row, column) {
      const property = column.property
      return row[property] === value
    },
    reloadTasks () {
      this.taskModalShow = false
      this.taskModalData = null
      this.$emit('reload')
    },
    closeTaskModal () {
      this.taskModalShow = false
      this.taskModalData = null
    },
    convertDate (date) {
      return this.toTimeZone(date).format(this.localeDateTimeFormat)
    },
    handleEdit (row) {
      // console.log(row)
      this.taskModalData = row
      this.taskModalShow = true
    },
    handleView (index, row) {
      this.$router.push(`/c/${this.companyId}/tasks/${row.id}/view`)
    },
    handleBankView (index, row) {
      this.$router.push(`/c/${this.companyId}/user-tasks/${row.id}`)
    },
    bulkUpdate () {
      // todo
      this.$refs.taskTable.clearSelection()
      this.selection = []
    },
    bulkDelete () {
      this.$confirm(this.$i18n?.t('Confirm tasks deletion'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        const payload = {
          action: 'delete',
          tasks: this.selection
        }
        this.$store.dispatch('bulkTasksUpdate', [this.companyId, payload]).then(() => {
          this.$refs.taskTable.clearSelection()
          this.selection = []
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    handleDelete (index, row) {
      this.$confirm(this.$t('This will permanently delete the task. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTask', [this.companyId, row.id]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          this.$emit('reload')
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>

<style scoped>

</style>
