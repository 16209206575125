<template>
  <div>
    <input
      id="autocomplete_container"
      class="form-control"
      type="text"
      :placeholder="$t(this.placeholder)"
    />
  </div>
</template>
<script>
import { Loader } from '@googlemaps/js-api-loader'
export default {
  name: 'google-map-autocomplete',
  emits: ['place_changed'],
  props: {
    address: String,
    placeholder: {
      type: String,
      default: 'Search address'
    }
  },
  async mounted () {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
      language: this.lang
    })
    const Places = await loader.importLibrary('places')
    const input = document.getElementById('autocomplete_container')
    input.value = this.address
    const options = {
      fields: ['formatted_address', 'geometry', 'name', 'place_id'],
      strictBounds: false
    }
    const autocomplete = new Places.Autocomplete(input, options)
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      this.$emit('place_changed', place)
    })
  },
  watch: {
    address () {
      const input = document.getElementById('autocomplete_container')
      input.value = this.address
    }
  }
}

</script>
<style>
.pac-container {
  background-color: #FFF;
  z-index: 2000;
  position: fixed;
  display: inline-block;
  float: left;
}

</style>
