<template>
  <modal-component :id="'ClickClientAddressModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t(clientAddress.id ? 'Update client address' : 'Create client address')}}</h5>
    </template>
    <template #body>
      <ClientAddressForm
        :clientAddressData="localClientAddress"
        @addressChange="addressChange"
      />
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import ClientAddressForm from '@/components/Tasks/CommonComponents/ClientAddressForm.vue'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'ClientAddressModal',
  components: {
    ClientAddressForm,
    ModalComponent: ModalComponent
  },
  mixins: [errorMixin],
  data () {
    return {
      localClientAddress: {}
    }
  },
  props: {
    clientAddress: Object,
    clientId: Number
  },
  created () {
    this.localClientAddress = JSON.parse(JSON.stringify(this.clientAddress))
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },
  watch: {
  },
  methods: {
    closeModal () {
      const elements = document.getElementsByClassName('pac-container')
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0])
      }
      document.getElementById('modalCloseButton').click()
      this.$emit('closeClientAddressModal')
    },
    addressChange (data) {
      this.localClientAddress = data
    },
    save () {
      let payload = {
        longitude: this.localClientAddress.longitude,
        latitude: this.localClientAddress.latitude,
        address: this.localClientAddress.address,
        address_data: this.localClientAddress.address_data,
        service_area_id: this.localClientAddress.service_area_id
      }

      if (!this.localClientAddress.address) {
        this.toastError(this.$i18n.t('Client address is required'))
        return
      }

      if (this.clientAddress.id) {
        this.$store.dispatch('updateClientAddress', [this.companyId, this.clientId, this.clientAddress.id, payload]).then(response => {
          this.toastSuccess(this.$i18n.t('Update completed!'))
          this.closeModal()
        }).catch(e => {
          this.toastError(this.$i18n.t(e.message))
        })
      } else {
        this.$store.dispatch('createClientAddress', [this.companyId, this.clientId, payload]).then(response => {
          this.toastSuccess(this.$i18n.t('Update completed!'))
          this.closeModal()
        }).catch(e => {
          this.toastError(this.$i18n.t(e.message))
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
