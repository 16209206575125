<template>
  <Form ref="custom">
    <div v-if="itemsState.length > 0">
      <div v-if="title" class="text-primary text-uppercase font-weight-bold mb-2">
        {{ $t(title) }}
      </div>
      <div v-for="(field, key) in itemsState" :key="'existing-' + field['title'] + '-' + key" class="mb-2">
        <template v-if="field['field_type'] === 'text'">
          <ui-text-input
            :name="'input-' + field['title']"
            v-model="itemsState[key]['value']"
            :validation="{required: !!field['required'] && !disabled}"
            :label="field['title']"
            :disabled="disabled"
          />
        </template>
        <template v-if="field['field_type'] === 'date_range_picker'">
          <ui-date-picker
            :pickerType="'daterange'"
            :label="field['title']"
            :name="'range-' + field['title']"
            v-model="itemsState[key]['options']"
            :validation="{required: !!field['required']}"
            :clearable="true"
            :picker-format="localeDateFormatElementUi"
          />
        </template>
        <template v-if="field['field_type'] === 'date_picker'">
          <ui-date-picker
            :label="field['title']"
            :name="'picker-' + field['title']"
            v-model="itemsState[key]['value']"
            :validation="{required: !!field['required']}"
            :clearable="true"
            :picker-format="localeDateFormatElementUi"
            :disabled="disabled"
            :picker-options="pickerOptions"
            :placeholder="$t('Pick a day')"
          />
        </template>
        <template v-else-if="field['field_type'] === 'number'">
          <ui-number-input
            :id="field['title']"
            :disabled="disabled"
            :label="field['title']"
            v-model="itemsState[key]['value']"
            :name="'input-number-' + field['title']"
            :validation="{required: field['required'], decimal: true}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'checkbox'">
          <ui-checkbox
            :id="field['title']"
            :name="'input-checkbox-' + field['title']"
            :disabled="disabled"
            v-model="itemsState[key]['value']"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'select'">
          <ui-select
            :id="field['title']"
            :name="'select-' + field['title']"
            :disabled="disabled"
            :label="field['title']"
            v-model="itemsState[key]['value']"
            class="w-100"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :clearable="true"
          />
        </template>
        <template v-else-if="field['field_type'] === 'multi_select'">
          <ui-multi-select
            :label="field['title']"
            :id="field['title']"
            :name="'multiselect-' + field['title']"
            v-model="itemsState[key]['options']"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :disabled="disabled"
          />
        </template>
        <template v-else-if="field['field_type'] === 'file'">
          <ui-file-upload
            name='file'
            :upload="upload"
            :auto-upload="true"
            :label="field['title']"
            :multiple="false"
            @onSuccess="success(field['id'])"
            :beforeUpload="(file) => beforeUpload(file, field)"
            :fileLimit="maxFileSize"
            :slotFile="true"
            :required="!!field['required']"
            :disabled="disabled"
          />
          <div class="row m-1 card">
            <div class="col-md-12 p-2">
              <div class="row">
                <div class="col-md-12 font-weight-bold">
                  {{$t('Attached files')}}
                </div>
              </div>
              <template v-if="field['value'] && field['value'].length > 0">
                  <div class="file-block p-2" v-for="(file, index) in field['value']" :key="'file_' + field['id'] + index">
                      <div class="d-flex justify-content-end">
                        <i
                          v-if="file.mime_type.slice(0, 5) === 'image'"
                          class="icon-eye text-primary m-1"
                          @click="handlePictureCardPreview(file)"
                          style="cursor: pointer; font-size: 20px"
                        >
                        </i>
                        <i
                          class="icon-download text-primary m-1"
                          @click="handleDownloadObject(file)"
                          style="cursor: pointer; font-size: 20px"
                        >
                        </i>
                        <i
                          v-if="!disabled"
                          class="icon-trash text-danger m-1"
                          @click="deleteFile(field['id'], index)"
                          style="cursor: pointer; font-size: 20px"
                        >
                        </i>
                      </div>
                      <div>
                        <div
                          v-if="file.mime_type && file.mime_type.slice(0, 5) === 'image'"
                          class="img-thumbnail uploaded-file"
                          v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                        ></div>
                        <div
                          v-else-if="file.mime_type === 'application/pdf'"
                          class="img-thumbnail uploaded-file"
                          v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                        ></div>
                        <div v-else
                          class="img-thumbnail uploaded-file"
                          v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                        ></div>
                      </div>
                      <div class="text-truncate align-bottom mt-2">{{file.name}}</div>
                  </div>
                  <el-dialog v-model="dialogVisible">
                    <img width="100%" :src="dialogUrl">
                  </el-dialog>
              </template>
              <template v-else>
                <div class="text-center p-5">
                  {{$t('No files found')}}
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div v-for="(field, key) in deletedItemsState" :key="'deleted-' + field['title']" class="pb-2">
        <template v-if="field['field_type'] === 'text'">
          <ui-text-input
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :validation="{required: !!field['required'] && !disabled}"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'number'">
          <ui-number-input
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            :label="field['title']"
            v-model="deletedItemsState[key]['value']"
            :validation="{required: field['required'], decimal: true}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'checkbox'">
          <ui-checkbox
            :id="field['title']"
            :name="'deleted-checkbox-' + field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'select'">
          <ui-select
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :label="field['title']"
            class="w-100"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'multi_select'">
          <ui-multi-select
            :label="field['title']"
            :id="field['title']"
            :name="field['title']"
            v-model="deletedItemsState[key]['options']"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :disabled="true"
          />
        </template>
      </div>
    </div>
  </Form>
</template>

<script>
import mixinMoment from '@/mixins/mixinMoment'

export default {
  name: 'AdditionalFieldsComponent',
  mixins: [mixinMoment],
  expose: ['resetValidation'],
  data () {
    return {
      pickerOptions: {
        cellClassName: 'ml-2'
      },
      itemsState: [],
      deletedItemsState: [],
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      maxFileSize: 102400,
      itemFiles: [],
      upload: {}
    }
  },
  props: {
    items: Array,
    deletedItems: Array,
    disabled: Boolean,
    title: { type: String, default: 'Additional fields' },
    taskId: Number
  },
  created () {
    if (this.taskId) {
      this.upload = {
        url: process.env.VUE_APP_ROOT_API + 'companies/' + this.$store.getters.companyId + '/tasks/' + this.taskId + '/file',
        headers: {
          Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
        }
      }
    }
    this.itemsState = this.items
    this.deletedItemsState = this.deletedItems
    this.$eventBus.on('validateClientFields', this.validate)
  },
  mounted () {
    setTimeout(() => {
      this.$refs.custom.resetForm()
    }, 500)
  },
  beforeUnmount () {
    this.$eventBus.off('validateClientFields')
  },
  watch: {
    items () {
      this.itemsState = []
      this.$nextTick(() => {
        this.itemsState = this.items
      })
    },
    deletedItems () {
      this.deletedItemsState = this.deletedItems
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    success (itemId) {
      this.files = []
      this.updateTaskChecklist(itemId)
    },
    updateTaskChecklist (itemId) {
      this.$store.dispatch('getTask', [this.companyId, this.taskId]).then((response) => {
        if (response.checklist_result) {
          const localChecklistItem = this.itemsState.findIndex(item => item.id === itemId)
          const responseChecklistItem = response.checklist_result.findIndex(item => item.id === itemId)
          if (localChecklistItem > -1 && responseChecklistItem > -1) {
            this.itemsState[localChecklistItem] = response.checklist_result[responseChecklistItem]
          }
        }
        this.$emit('updateItems', this.itemsState)
      }).catch(() => {})
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../../public/static/images/App/file.png')
      }
    },
    beforeUpload (file, field) {
      const formats = [
        'application/pdf',
        'image/jpeg',
        'image/gif',
        'image/jpg',
        'image/png',
        'video/quicktime',
        'video/mp4',
        'video/avi',
        'video/mov'
      ]

      if (formats.indexOf(file.type) === -1) {
        this.toastError(this.$i18n?.t('File format should be: jpeg, jpg, png, gif, mp4, mov, avi, pdf'))
        return false
      }

      if (file.size / 1024 > this.maxFileSize) {
        this.toastError(this.$i18n?.t('File size can not exceed 102400 kb!'))
        return false
      }

      this.upload.payload = {
        checklist_id: field.checklist_id,
        item_id: field.id
      }
    },
    resetValidation () {
      setTimeout(() => {
        this.$refs.custom.resetForm()
      }, 500)
    },
    handlePictureCardPreview (file) {
      this.dialogUrl = file.url
      this.dialogVisible = true
      this.dialogType = file.mime_type
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    deleteFile (itemId, valueIndex) {
      this.$store.dispatch('deleteTaskChecklistItemFile', [this.companyId, this.taskId, itemId, valueIndex]).then(() => {
        this.updateTaskChecklist(itemId)
      })
    },
    validate () {
      if (this.disabled) {
        this.$emit('proceedSave')
      } else {
        this.$refs.custom.validate().then(val => {
          if (val.valid) {
            this.$emit('proceedSave')
          }
        }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>

</style>
