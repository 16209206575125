<template>
  <div>
    <div v-if="!hideTitle" class="my-2 text-primary text-uppercase font-weight-bold">{{ $t('MAIN_EMPLOYEE_ATTACH_TITLE') }}</div>
    <el-container class="d-block" style="max-height: 300px; overflow-y: auto; background-color: #ebe8e8">
      <template v-if="shifts.length > 0">
        <div v-for="item in shifts"
             v-bind:key="item.id"
             @click="changeMainShift(item.id)"
             class="m-1 px-1 font-weight-bold bg-white">
          <ui-radio
            :name="item.id + '__selectedShift'"
              :containerClass="'mt-2 mb-2 px-1'"
              v-model="selectedShift"
              :set-value="item.id"
              :disabled="temporarySubtasks.length > 0"
          >
            <div class="text-wrap d-inline-block" :for="'chck_' + item.id">
              <div>{{ from(item.time_from) }} - {{ to(item.time_to) }}
                {{item.employee.full_name}}
                <el-tooltip v-if="item.calculatedLeadTime > item.duration" class="item" effect="light" :content="$t('WARNING_TASKS_DURATION')" placement="top">
                  <i class="icon-alert-circle text-danger font-weight-bold"></i>
                </el-tooltip>
              </div>
              <div>
                {{ $t('COUNT_TASKS') }} {{ item.tasksCount }}
                <template v-if="isEmployeeHasPermission('create-schedule')">
                  {{ $t('TOTAL_TASKS_TIME_HRS') }} {{ item.calculatedLeadTime / 60 }}
                </template>
              </div>
            </div>
          </ui-radio>
        </div>
      </template>
      <template v-else>
        <div class="text-center my-3">
          {{ $t('NO_SHIFTS_FOUND') }}
        </div>
      </template>
    </el-container>
    <SubtaskCreateComponent
      v-if="!userTask && selectedShift && showSubtaskCreate"
      :scheduleId="scheduleId"
      :tz="tz"
      :clientId="clientId"
      :scheduledTime="scheduledTime"
      :leadTime="leadTime"
      :parentShift="selectedShift"
      ref="subtaskCreateComponent"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import SubtaskCreateComponent from '@/components/Tasks/Task/Components/SubtaskCreateComponent'

export default {
  name: 'ShiftAttachComponent',
  components: { SubtaskCreateComponent },
  mixins: [momentMixin],
  data () {
    return {
      shifts: [],
      selectedShift: null,
      moment: moment
    }
  },
  props: {
    scheduleId: Number,
    date: [String, Date],
    taskId: Number,
    leadTime: Number,
    scheduledTime: [String, Date],
    tz: String,
    userTask: { type: Boolean, default: false },
    clientId: { type: Number, default: 0 },
    hideTitle: { type: Boolean, default: false },
    showSubtaskCreate: { type: Boolean, default: true }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    }),
    temporarySubtasks () {
      return this.$store.getters.temporarySubtasks
    }
  },
  methods: {
    init () {
      this.shifts = []
      this.selectedShift = null
      this.moment = moment
      // console.log(this.leadTime)
      let date = null
      if (this.date) {
        date = this.fromZoneToZone(this.date, this.tz, 'UTC').format(this.backendDateTimeFormat)
        // console.log(date)
      }
      let resourse = 'getShiftsToAttach'
      let payload = [
        this.companyId,
        this.scheduleId,
        date,
        this.clientId,
        this.leadTime
      ]

      if (this.userTask) {
        resourse = 'getUserTaskShifts'
        payload = [
          this.companyId,
          this.taskId,
          date,
          this.leadTime
        ]
      }
      this.$store.dispatch(resourse, payload).then(response => {
        const shifts = {}
        response.forEach(shift => {
          shifts[shift.id] = shift
          let leadTime = 0
          shifts[shift.id].tasksCount = 0
          shifts[shift.id].tasks.forEach(task => {
            if (+task.id === +this.taskId) {
              this.selectedShift = shift.id
            } else {
              leadTime += +task.lead_time * 60
              shifts[shift.id].tasksCount++
            }
          })
          shifts[shift.id].calculatedLeadTime = leadTime
        })

        this.shifts = Object.values(shifts)
        let shiftsWithClients = this.shifts.filter(item => item.distance >= 0)
        let shiftsWithoutClients = this.shifts.filter(item => item.distance < 0 || item.distance === undefined)
        shiftsWithClients = shiftsWithClients.sort((a, b) => {
          if (a.distance > b.distance) {
            return 1
          }
          if (a.distance < b.distance) {
            return -1
          }
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }

          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })

        shiftsWithoutClients = shiftsWithoutClients.sort((a, b) => {
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }
          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })
        this.shifts = shiftsWithClients.concat(shiftsWithoutClients)
      })
    },
    from (timeFrom) {
      try {
        return this.fromZoneToZone(timeFrom, 'UTC', this.tz).format(this.localeDateTimeFormat)
      } catch (e) {
        console.log('timeFrom', e)
      }
    },
    to (timeTo) {
      try {
        return this.fromZoneToZone(timeTo, 'UTC', this.tz).format(this.localeDateTimeFormat)
      } catch (e) {
        console.log('timeTo', e)
      }
    },
    changeMainShift (id) {
      if (this.temporarySubtasks.length === 0) {
        this.selectedShift = id
      }
    }
  },
  watch: {
    selectedShift (value, oldValue) {
      const index = this.shifts.findIndex(item => +item.id === +value)
      const oldIndex = this.shifts.findIndex(item => +item.id === +oldValue)
      // console.log(index, oldIndex, this.shifts)
      if (oldIndex >= 0) {
        this.shifts[oldIndex].calculatedLeadTime -= this.leadTime * 60
        this.shifts[oldIndex].tasksCount--
      }
      if (index >= 0) {
        this.shifts[index].calculatedLeadTime += this.leadTime * 60
        this.shifts[index].tasksCount++
      }
      this.$emit('attachShift', value)
    },
    leadTime (value, oldValue) {
      if (this.selectedShift) {
        const index = this.shifts.findIndex(item => +item.id === +this.selectedShift).toString()
        this.shifts[index].calculatedLeadTime -= oldValue * 60
        this.shifts[index].calculatedLeadTime += value * 60
      }
      // костыль для обновления счетчиков на шифтах (свойства объекта не реактивные?!)
      const tmp = this.selectedShift
      this.selectedShift = null
      this.selectedShift = tmp
    },
    taskId () {
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
