<template>
  <Form ref="observe" as="div">
    <div class='row mb-2 mx-auto'>
      <nav aria-label='breadcrumb'>
        <ol class='breadcrumb bg-transparent p-0 m-0'>
          <li class='breadcrumb-item title-text'>
            <router-link :to="{ name: 'taskServiceAreas', params: { companyId: companyId} }">
              <span class='breadcrumb-item'>{{ $t('SERVICE_AREAS_TITLE')}}</span>
            </router-link>
          </li>
          <li class='breadcrumb-item title-text active' aria-current='page'>
            <span class='title-text'>{{serviceAreaId ? $t('UPDATE_SERVICE_AREA_TITLE') : $t('CREATE_SERVICE_AREA_TITLE')}}</span>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="errors" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
                <ui-text-input
                  :label="$t('SERVICE_AREA_TITLE')"
                  id="title"
                  :name="'title'"
                  :apiErrors="apiErrors"
                  v-model="serviceArea.title"
                  :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
                  :placeholder="$t('Title')"
                />
              </div>
              <div class="col-md-6">
                <label class="col-form-label font-weight-bold">{{ $t('SEARCH_ADDRESS_TITLE') }}</label>
                <GmvAutocomplete
                  :value="address"
                  :options="{fields: ['geometry', 'formatted_address']}"
                  class="form-control"
                  @place_changed='setPlace'
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <ui-multi-select
                  name="employees"
                  :label="$t('Employees')"
                  v-model="serviceArea.employees"
                  :options="employeesList"
                  :clearable="true"
                  :key-name="'id'"
                  :label-name="'full_name'"
                  :value-name="'id'"
                />
              </div>
              <div class="col-md-6">
                <ui-color-picker
                  name="color"
                  :label="$t('Choose a color:')"
                  v-model="serviceArea.color"
                  :colors="colorsChoices"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                  <label class="col-form-label font-weight-bold">{{ $t('CLEAR_POLYGON_TITLE') }}</label>
                  <div>
                    <button
                      class="btn btn-outline-primary btn-sm rounded-pill"
                      @click="clearPolygon">
                      {{ $t('CLEAR_POLYGON') }}
                    </button>
                  </div>
              </div>
            </div>
            <el-divider />
            <div class="row">
              <div class="col text-center">
                <button
                  class="btn btn-success btn-sm rounded-pill mt-2"
                  @click="save">
                  {{ $t('SAVE_CHANGES') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <GmvMap
              ref="mapRef"
              :center="center"
              @click="mapClick($event)"
              :zoom='18'
              style='width:100%;  height: 600px;'
            >
              <GmvPolygon
                ref="polyRef"
                v-if="polygonEnable"
                :draggable="true"
                :editable="true"
                :paths="polygon"
                @paths_changed="polygonChanged($event)"
              />
            </GmvMap>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import colors from '@/mixins/mixinColors'
import { utilities } from '@gmap-vue/v3'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'serviceArea',
  mixins: [errorMixin, colors],
  data () {
    return {
      serviceArea: {
        id: null,
        title: null,
        allowed_radius: 100,
        geometry: [],
        employees: [],
        color: '#479d3b'
      },
      address: null,
      longitude: null,
      latitude: null,
      center: { lat: 45.508, lng: -73.587 },
      places: [],
      polygon: [],
      google: null,
      polygonEnable: true
    }
  },
  created () {
    this.initGoogle()
    if (this.google) {
      this.init()
    }
  },
  computed: {
    serviceAreaId () {
      return this.$route.params.serviceAreaId
    },
    companyId () {
      return this.$store.getters.companyId
    },
    locale () {
      return this.$store.getters.locale
    },
    employeesList () {
      return this.$store.getters.employees
    }
  },
  watch: {
    google () {
      if (this.google) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      if (this.serviceAreaId) {
        this.$store.dispatch('getTasksServiceArea', [this.companyId, this.serviceAreaId]).then(response => {
          this.serviceArea = response
          if (this.serviceArea.geometry && this.serviceArea.geometry.coordinates) {
            const polygon = []
            this.serviceArea.geometry.coordinates[0].forEach(point => {
              polygon.push({
                lng: +point[0],
                lat: +point[1]
              })
            })

            if (polygon) {
              this.latitude = polygon[0].lat
              this.longitude = polygon[0].lng
            }
            this.polygon = polygon
          }
          this.geolocate()
        })
      } else {
        this.geolocate()
      }
    },
    initGoogle () {
      this.google = getGoogleMapsAPI()
      if (!this.google) {
        setTimeout(() => {
          this.initGoogle()
        }, 1000)
      }
    },
    setPlace (place) {
      if (place.formatted_address && place.geometry) {
        this.address = place.formatted_address
        this.latitude = place.geometry.location.lat()
        this.longitude = place.geometry.location.lng()
        this.center = {
          lat: this.latitude,
          lng: this.longitude
        }
        this.$refs.mapRef.mapPromise?.then((map) => {
          map.panTo(this.center)
        })
      } else {
        this.address = place.name
      }
    },
    geolocate () {
      if (this.$refs.mapRef) {
        if (this.latitude && this.longitude) {
          this.center = {
            lat: +this.latitude,
            lng: +this.longitude
          }
        } else {
          navigator.geolocation.getCurrentPosition(position => {
            if (position) {
              this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              this.latitude = position.coords.latitude
              this.longitude = position.coords.longitude

              const geocoder = new this.google.maps.Geocoder()
              const latlng = {
                lat: parseFloat(this.latitude),
                lng: parseFloat(this.longitude)
              }

              geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK') {
                  if (results[0]) {
                    this.address = results[0].formatted_address
                  } else {
                    console.log('No results found')
                  }
                } else {
                  console.log(status)
                }
              }).catch(() => {})
            }
          })
        }
        this.$refs.mapRef.mapPromise?.then((map) => {
          map.panTo(this.center)
        })
      } else {
        setTimeout(() => {
          this.geolocate()
        }, 1000)
      }
    },
    toGeoJSON (polygon) {
      // lng - lat
      const paths = []
      polygon.forEach(path => {
        paths.push([path.lng, path.lat])
      })
      if (JSON.stringify(paths[0]) !== JSON.stringify(paths[paths.length - 1])) { // first and last point must be the same
        paths.push(paths[0])
      }
      this.serviceArea.geometry = {
        type: 'Polygon',
        coordinates: [paths]
      }
    },
    mapClick (val) {
      this.polygonEnable = false
      const position = {
        lat: val.latLng.lat(),
        lng: val.latLng.lng()
      }
      // т.к. полигон должен быть замкнутым (координаты первой точки должны совпадать с координатами последней),
      // сначала удалям последнее значение массива (которое равно первому), добавляем новую точку,
      // а затем снова копируем первое значение массива в конец массива
      if (this.polygon.length > 1) {
        this.polygon.splice(this.polygon.length - 1)
      }
      this.polygon.push(position)
      this.polygon.push(this.polygon[0])
      this.toGeoJSON(this.polygon)
      this.$nextTick(() => {
        this.polygonEnable = true
      })
    },
    polygonChanged (mvcArray) {
      this.polygon = []
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          this.polygon.push({ lat: point.lat(), lng: point.lng() })
        }
      }
      this.toGeoJSON(this.polygon)
    },
    clearPolygon () {
      this.polygon = []
      this.toGeoJSON(this.polygon)
    },
    save () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          if (this.serviceAreaId) {
            this.$store.dispatch('updateTasksServiceArea', [this.companyId, this.serviceAreaId, this.serviceArea]).then(() => {
              this.toastSuccess(this.$i18n.t('SERVICE_AREA_UPDATE_MESSAGE'))
            }).catch((error) => {
              this.toastError(this.$t(error.response.data.message))
            })
          } else {
            this.$store.dispatch('createTasksServiceArea', [this.companyId, this.serviceArea]).then(() => {
              this.toastSuccess(this.$i18n.t('SERVICE_AREA_CREATE_MESSAGE'))
              this.$router.push(`/c/${this.companyId}/tasks-service-areas`)
            }).catch((error) => {
              this.toastError(this.$t(error.response.data.message))
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
